/**
 * Vanilla API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type OrderAction = 'WAIT_PAYMENT' | 'CREATED' | 'PACKED' | 'DECORED' | 'TAKEN' | 'SHIPPED' | 'RETURNED' | 'RECEIVED' | 'REFUNDED' | 'CANCELLED';

export const OrderAction = {
    WAIT_PAYMENT: 'WAIT_PAYMENT' as OrderAction,
    CREATED: 'CREATED' as OrderAction,
    PACKED: 'PACKED' as OrderAction,
    DECORED: 'DECORED' as OrderAction,
    TAKEN: 'TAKEN' as OrderAction,
    SHIPPED: 'SHIPPED' as OrderAction,
    RETURNED: 'RETURNED' as OrderAction,
    RECEIVED: 'RECEIVED' as OrderAction,
    REFUNDED: 'REFUNDED' as OrderAction,
    CANCELLED: 'CANCELLED' as OrderAction
};

