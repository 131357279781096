import { GisStore } from './gis.service';
import { CityDeliveryMap, YaFeature, YaPolygon } from '../../interface';
import { AddressModel } from '../../vn-api';

export function createAddressOptions(addresses: AddressModel[], cityDeliveryArea: CityDeliveryMap, storeDeliveryMap: Map<number, YaFeature[]>,
                                     stores: GisStore[], store?: GisStore): AddressModel[] {
  if (addresses || cityDeliveryArea) {
    const hasDeliveryFn = (lat: number, lng: number) => {
      if (store) {
        return hasStoreDelivery(lat, lng, storeDeliveryMap, store);
      } else if ((stores ?? []).length > 0) {
        return !!getDeliveryStore(lat, lng, storeDeliveryMap, stores);
      }
      return false;
    };
    return (addresses ?? []).filter(addr => addr.latitude && addr.longitude && hasDeliveryFn(addr.latitude, addr.longitude))
      .sort((a, b) => {
        if (a.alias && b.alias) {
          return a.alias.localeCompare(b.alias);
        }
        if (a.alias) {
          return -1;
        }
        if (b.alias) {
          return 1;
        }
        if (a.lastUsageDate && b.lastUsageDate) {
          return new Date(b.lastUsageDate).getMilliseconds() - new Date(a.lastUsageDate).getMilliseconds();
        }
        return b.address.localeCompare(a.address);
      })
      .slice(0, 7);
  }
  return [] as AddressModel[];
}

export function hasStoreDelivery(lat: number, lng: number, storeDeliveryMap: Map<number, YaFeature[]>, store: GisStore): boolean {
  return getCurrentStoreCoordinates(storeDeliveryMap, store).some(polygon => isMarkerInsidePolygon(polygon, lat, lng));
}

export function getDeliveryStore(lat: number, lng: number, storeDeliveryMap: Map<number, YaFeature[]>, stores: GisStore[]): GisStore | undefined {
  let store: GisStore | undefined;
  storeDeliveryMap.forEach((features, storeId) => {
    const hasDelivery = features.some(feature => feature.geometry.type === 'Polygon' && isMarkerInsidePolygon(feature.geometry as YaPolygon, lat, lng));
    if (hasDelivery) {
      store = stores.find(s => s.id === storeId);
    }
  });
  return store;
}

export function isMarkerInsidePolygon(polygon: YaPolygon, lat: number, lng: number): boolean {
  const points = polygon.coordinates[0];
  const x = lat;
  const y = lng;

  let inside = false;
  for (let i = 0, j = points.length - 1; i < points.length; j = i++) {
    const xi = points[i][0];
    const yi = points[i][1];
    const xj = points[j][0];
    const yj = points[j][1];

    const intersect = ((yi > y) !== (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
    if (intersect) {
      inside = !inside;
    }
  }
  return inside;
}

export function getCurrentStoreCoordinates(storeDeliveryMap: Map<number, YaFeature[]>, store: GisStore): YaPolygon[] {
  const features: YaFeature[] | undefined = storeDeliveryMap.get(store.id);
  if (!features) {
    throw new Error('store delivery map empty for key: ' + store.id);
  }
  return features.filter(feature => feature.geometry.type === 'Polygon').map(d => (d.geometry as YaPolygon));
}
