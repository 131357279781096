import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SmsInfo } from '../interface';
import { AppEventModel } from '../vn-api';

@Injectable({
  providedIn: 'root'
})
export class HxEventService {
  constructor(
    private httpService: HttpClient,
  ) {
  }

  getOrderEventList(orderId: number): Observable<AppEventModel[]> {
    return this.httpService.get<AppEventModel[]>(`/api/vanilla/events/Order/${orderId}`);
  }

  getInvoiceEventList(orderId: number): Observable<AppEventModel[]> {
    return this.httpService.get<AppEventModel[]>(`/api/vanilla/events/Invoice/${orderId}`);
  }

  getSmsInfo(eventId: number): Observable<SmsInfo> {
    return this.httpService.get<SmsInfo>(`/api/vanilla/events/${eventId}/sms-info`);
  }

  getDiff(opt: { newEventId: number; oldEventId: number }): Observable<any> {
    return this.httpService.post<any>(`/api/vanilla/events/json`, opt);
  }

  getStorageInvoiceEventList(storageInvoiceId: number): Observable<AppEventModel[]> {
    return this.httpService.get<AppEventModel[]>(`/api/vanilla/events/StorageInvoice/${storageInvoiceId}`);
  }

  getPaymentApprovalEventList(paymentApprovalId: number): Observable<AppEventModel[]> {
    return this.httpService.get<AppEventModel[]>(`/api/vanilla/events/PaymentApproval/${paymentApprovalId}`);
  }
}
