import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe Currency - show currency symbol
 */
@Pipe({
  name: 'priceLabel'
})
export class HxPriceLabelPipe implements PipeTransform {

  transform(value: number): string {
    if (!value) {
      return '';
    }

    if (value < 1) {
      if (value === 0.5) {
        return '1/2';
      } else if (value === 0.25) {
        return '1/4';
      } else if (value === 0.125) {
        return '1/8';
      } else if (value === 0.0625) {
        return '1/16';
      }
    }
    return '' + value;
  }

}
