/**
 * Vanilla API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ProductStatus = 'RESERVED' | 'RECEIVED' | 'PAID_CANCELLED' | 'CANCELLED' | 'REFUNDED' | 'CART' | 'CART_CANCELLED' | 'TRANSFER' | 'DISCARDED' | 'REGISTER' | 'INVENTORY';

export const ProductStatus = {
    RESERVED: 'RESERVED' as ProductStatus,
    RECEIVED: 'RECEIVED' as ProductStatus,
    PAID_CANCELLED: 'PAID_CANCELLED' as ProductStatus,
    CANCELLED: 'CANCELLED' as ProductStatus,
    REFUNDED: 'REFUNDED' as ProductStatus,
    CART: 'CART' as ProductStatus,
    CART_CANCELLED: 'CART_CANCELLED' as ProductStatus,
    TRANSFER: 'TRANSFER' as ProductStatus,
    DISCARDED: 'DISCARDED' as ProductStatus,
    REGISTER: 'REGISTER' as ProductStatus,
    INVENTORY: 'INVENTORY' as ProductStatus
};

