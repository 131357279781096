import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { GisAttributeInput, GisItem, GisService, MapClickHandler, MapMarker } from './gis.service';
import { HxYandexService } from './yandex.service';
import { getDeliveryStore, hasStoreDelivery } from './gis-util';
import { OrderDeliveryInfoModel } from '../../vn-api';
import { CityDeliveryMap, YaFeature } from '../../interface';

@Injectable({
  providedIn: 'root'
})
export class HxYandexInfoService extends GisService {
  private yMap: any;
  private ymaps: any;
  private clusterer: any;

  constructor(
    private tr: TranslocoService,
    private yandexService: HxYandexService
  ) {
    super();
  }

  clearMapData() {
    if (this.yMap && this.yMap.balloon) {
      this.yMap.balloon.close();
    }
  }

  initGisHeatMap(cityDeliveryArea: CityDeliveryMap, orderInfos: OrderDeliveryInfoModel[]): Promise<void> {
    const self = this;
    return new Promise<void>((resolve, reject) => {
      self.ymaps.ready().done(() => {
        try {
          self.yMap = new self.ymaps.Map('map', {
            center: [cityDeliveryArea.center?.lat, cityDeliveryArea.center?.lng],
            zoom: 11,
            controls: []
          });
          self.yandexService.loadHeatMap().then(() => {
            self.ymaps.modules.require(['Heatmap'], function (Heatmap: any) {
              const data = orderInfos.map(r => [r.point.latitude, r.point.longitude]), heatmap = new Heatmap(data);
              heatmap.setMap(self.yMap);
            });
          });
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  initGisClusterMap(cityDeliveryArea: CityDeliveryMap, orderInfos: OrderDeliveryInfoModel[]): Promise<void> {
    const self = this;
    return new Promise<void>((resolve, reject) => {
      self.ymaps.ready().done(() => {
        try {
          self.yMap = new self.ymaps.Map('map', {
            center: [cityDeliveryArea.center?.lat, cityDeliveryArea.center?.lng],
            zoom: 11,
            controls: []
          });

          this.clusterer = new self.ymaps.Clusterer({
            preset: 'islands#invertedLightBlueClusterIcons',
            groupByCoordinates: false,
            clusterDisableClickZoom: true,
            clusterHideIconOnBalloonOpen: false,
            geoObjectHideIconOnBalloonOpen: false
          });


          let geoObjects = [];
          for(let i = 0, len = orderInfos.length; i < len; i++) {
            const orderInfo = orderInfos[i];
            const point = orderInfos.map(r => [r.point.latitude, r.point.longitude])[i];
            geoObjects[i] = new self.ymaps.Placemark(point, this.getPointData(orderInfo), { preset: 'islands#lightBlueIcon'});
          }

          this.clusterer.options.set({
            gridSize: 80,
            clusterDisableClickZoom: true
          });
          this.clusterer.add(geoObjects);
          self.yMap.geoObjects.add(this.clusterer);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  getPointData(orderInfo: OrderDeliveryInfoModel) {
    return {
      balloonContentHeader: '<font size=3><b><a target="_blank" href="/orders/' + orderInfo.orderId + '">' + this.tr.translate('delivery-heat-count-map.order') + ' <strong>' + orderInfo.uniqueNumber + '</a></b></font>',
      balloonContentBody: '<p> ' + this.tr.translate('delivery-heat-count-map.orderAddress') + ' ' + orderInfo.address + '</p>',
      clusterCaption: this.tr.translate('delivery-heat-count-map.order') + ' <strong>' + orderInfo.uniqueNumber + '</strong>'
    };
  }

  initGisMap(url: string, attr: GisAttributeInput, clickHandler: MapClickHandler): Promise<void> {
    const lat = attr.gisItem?.point?.lat;
    const lng = attr.gisItem?.point?.lon;
    const self = this;
    return new Promise<void>((resolve, reject) => {
      self.ymaps.ready().done(() => {
        try {
          self.yMap = new self.ymaps.Map('map', {
            center: [attr.cityDeliveryArea?.center?.lat, attr.cityDeliveryArea?.center?.lng],
            zoom: 11,
            controls: []
          });
          if (attr.store) {
            const features: YaFeature[] = attr.storeDeliveryMap.get(attr.store.id) ?? [];
            features.forEach(feature => {
              self.createGeo(url, feature, self.yMap, attr, clickHandler);
            });
          } else if ((attr.stores ?? []).length > 0) {
            const features: YaFeature[] = Array.from(attr.storeDeliveryMap.values()).reduce((a, b) => a.concat(b), []);
            features.forEach(feature => {
              self.createGeo(url, feature, self.yMap, attr, clickHandler);
            });
          }

          // Добавление многоугольников в группу
          if (lat && lng && attr.gisItem) {
            if ((attr.store && hasStoreDelivery(lat, lng, attr.storeDeliveryMap, attr.store)) || ((attr.stores ?? []).length > 0
              && !!getDeliveryStore(lat, lng, attr.storeDeliveryMap, attr.stores))) {
              if (!self.yMap.balloon.isOpen()) {
                self.yMap.balloon.open([lat, lng], {contentHeader: attr.gisItem.addressName});
              } else {
                self.yMap.balloon.close();
              }
            } else {
              if (!self.yMap.balloon.isOpen()) {
                const text = self.tr.translate('error.no.store.delivery');
                self.yMap.balloon.open([lat, lng], {contentHeader: text});
              } else {
                self.yMap.balloon.close();
              }
            }
          }

          self.yMap.events.add('click', (e: any) => {
            const coords = e.get('coords');
            if (!self.yMap.balloon.isOpen()) {
              const text = self.tr.translate('error.no.store.delivery');
              self.yMap.balloon.open(coords, {contentHeader: text});
            } else {
              self.yMap.balloon.close();
            }
          });
          resolve();
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  searchStreets(term: string, url: string, param: { regionId?: number; bbox?: string }, attr: GisAttributeInput): Promise<GisItem[]> {
    const params = {
      bbox: attr.cityDeliveryArea.bbox,
      kind: 'house',
    };
    return this.yandexService.find(term, url, params).then(items => {
      items.forEach(item => {
        if (!item.point?.lat || !item.point.lon) {
          item.disabled = true;
        } else if (attr.store) {
          item.disabled = !hasStoreDelivery(item.point.lat, item.point.lon, attr.storeDeliveryMap, attr.store);
        } else if ((attr.stores ?? []).length > 0) {
          const deliveryStore = getDeliveryStore(item.point.lat, item.point.lon, attr.storeDeliveryMap, attr.stores);
          item.disabled = !deliveryStore;
        }
      });
      console.log('[yandex] load:', items);
      return items;
    }).catch(err => {
      console.log('[yandex] searchStreets error:', err);
      return [];
    });
  }

  showMap(url: string, attr: GisAttributeInput, clickHandler: MapClickHandler): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.yandexService.load().then(() => {
        // @ts-ignore
        this.ymaps = window['ymaps'];
        setTimeout(() => this.initGisMap(url, attr, clickHandler).then(resolve, reject), 100);
      }, reject);
    });
  }

  showHeatMap(cityDeliveryArea: CityDeliveryMap, orderInfos: OrderDeliveryInfoModel[]): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.yandexService.load().then(() => {
        // @ts-ignore
        this.ymaps = window['ymaps'];
        setTimeout(() => this.initGisHeatMap(cityDeliveryArea, orderInfos).then(resolve, reject), 100);
      }, reject);
    });
  }

  showClusterMap(cityDeliveryArea: CityDeliveryMap, orderInfos: OrderDeliveryInfoModel[]): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.yandexService.load().then(() => {
        // @ts-ignore
        this.ymaps = window['ymaps'];
        setTimeout(() => this.initGisClusterMap(cityDeliveryArea, orderInfos).then(resolve, reject), 100);
      }, reject);
    });
  }

  destroyMap() {
    if (this.yMap) {
      this.yMap.destroy();
      this.yMap = undefined;
    }
    if (this.clusterer) {
      this.clusterer = undefined;
    }
  }

  updateMarker(data: MapMarker, attr: GisAttributeInput): void {
    if (!this.yMap || !this.ymaps) {
      return;
    }
    console.debug('[yandex] updateMarker', data);
    this.yMap.balloon?.close();
    const {latitude, longitude, addressName} = data;
    if ((attr.store && hasStoreDelivery(latitude, longitude, attr.storeDeliveryMap, attr.store))
      || (attr.stores ?? []).length > 0 && !!getDeliveryStore(latitude, longitude, attr.storeDeliveryMap, attr.stores)) {
      if (this.yMap && this.ymaps && this.yMap.balloon) {
        this.yMap.balloon.open([latitude, longitude], {contentHeader: addressName});
      }
    } else {
      this.yMap.balloon.open([latitude, longitude], {contentHeader: this.tr.translate('error.no.store.delivery')});
    }
  }

  showEmptyMap(url: string): Promise<void> {
    return Promise.resolve();
  }

  initGisEmptyMap(url: string): Promise<void> {
    return Promise.resolve();
  }

  private createGeo(url: string, feature: YaFeature, yMap: any, attr: GisAttributeInput, clickHandler: MapClickHandler) {
    const deliveryZones = this.ymaps.geoQuery(feature).addToMap(yMap);
    // @ts-ignore
    deliveryZones.each(obj => {
      obj.options.set({
        fillColor: obj.properties.get('fill'),
        fillOpacity: obj.properties.get('fill-opacity'),
        strokeColor: obj.properties.get('stroke'),
        strokeWidth: obj.properties.get('stroke-width'),
        strokeOpacity: obj.properties.get('stroke-opacity'),
        zIndexActive: 100
      });

      obj.events.add('click', (e: any) => {
        const coords = e.get('coords');
        const latitude = coords[0];
        const longitude = coords[1];
        const params = {
          bbox: attr.cityDeliveryArea.bbox,
        };
        this.yMap.balloon?.close();
        this.yandexService.find([longitude, latitude].join(','), url, params).then(res => {
          if (res.length > 0) {
            clickHandler(res[0]);
            this.yMap.balloon.open(coords, {contentHeader: res[0].addressName});
          } else {
            clickHandler(undefined);
            this.yMap.balloon.open(coords, {contentHeader: this.tr.translate('error.dgis.address.notFound')});
          }
        });
      });
    });
  }
}
