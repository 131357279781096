import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CountryModel } from '../vn-api';

@Injectable({
  providedIn: 'root'
})
export class HxCallcenterService {
  constructor(
    private http: HttpClient,
  ) { }

  getCallUrlById(id: string): Observable<{url: string}> {
    return this.http.get<{url: string}>(`/api/vanilla/callcenter/calls/${id}/url`);
  }

  getLastCallByAsteriskId(asteriskId: string): Observable<LastCallInfo> {
    return this.http.get<LastCallInfo>(`/api/vanilla/callcenter/operators/${asteriskId}/last-call`);
  }

  addOrderToCall(params: {callId: string, orderId: number}): Observable<void> {
    return this.http.patch<void>(`/api/vanilla/callcenter/calls/${params.callId}/orders`, {orderId: params.orderId});
  }

  getOperatorIds(): Observable<number[]> {
    return this.http.get<number[]>(`/api/vanilla/callcenter/operator-ids`);
  }
}

export interface LastCallInfo {
  phone: string;
  callId: string;
  event: string;
  mobile: boolean;
  country: CountryModel;
  orderIds: number[];
  cartOrderIds: number[];
}
