import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { DatePropertyResourceService, DecorResponse, InscriptionRequest } from '../vn-api';


@Injectable({
  providedIn: 'root'
})
export class HxDatePropertyService {

  constructor(
    private http: HttpClient,
    private datePropertyResourceService: DatePropertyResourceService
  ) { }

  getDecor(params: { date: string, storeId: number }): Promise<DecorResponse> {
    return firstValueFrom(this.datePropertyResourceService.getDecor(params.date, params.storeId));
  }

  updateInscription(id: number, req: InscriptionRequest): Promise<DecorResponse> {
    return firstValueFrom(this.datePropertyResourceService.updateInscription(id, req));
  }
}
