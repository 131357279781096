import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';
import { ClientBasicModel, ClientPropertyModel, CompanyModel, PagedList } from '../interface';
import { toHttpParams } from '../utils/globals';
import { ClientRequest, ClientResourceService, ClientFullModel, PagedClientFullModel, SortType, CommentModel, ClientModel, AddressModel, ContactModel } from '../vn-api';

@Injectable({
  providedIn: 'root'
})
export class HxClientService {
  constructor(
    private httpClient: HttpClient,
    private clientResourceService: ClientResourceService
  ) {
  }

  getClientById(id: number): Promise<ClientFullModel> {
    return firstValueFrom(this.clientResourceService.getClientById(id));
  }

  searchClient(searchText: string): Observable<ClientBasicModel[]> {
    return this.httpClient.get<ClientBasicModel[]>(`/api/vanilla/clients/search?q=${searchText}`);
  }

  getClientList(params?: {
    page?: number;
    orderBy?: string,
    limit?: number,
    phone?: string,
    query?: string,
    sort?: SortType,
    storeIds?: number[]
  }): Promise<PagedClientFullModel> {
    return firstValueFrom(this.clientResourceService.getClients(params?.limit, params?.orderBy, params?.page, params?.phone, params?.query, params?.sort, params?.storeIds));
  }

  getClientByPhone(phone: string): Promise<ClientFullModel> {
    return firstValueFrom(this.clientResourceService.getClientByPhone(phone));
  }

  getClient(id: number): Promise<ClientFullModel> {
    return firstValueFrom(this.clientResourceService.getClientById(id));
  }

  getClientBrandProperties(id: number): Observable<ClientPropertyModel[]> {
    return this.httpClient.get<ClientPropertyModel[]>(`/api/vanilla/clients/${id}/properties`);
  }

  saveClientBrandProperty(id: number, body: Partial<ClientPropertyModel>): Observable<{ id: number }> {
    return this.httpClient.post<{ id: number }>(`/api/vanilla/clients/${id}/properties`, body);
  }

  removeClientBrandProperty(id: number): Observable<void> {
    return this.httpClient.delete<void>(`/api/vanilla/clients/properties/${id}`);
  }

  removeAddress(id: number): Observable<void> {
    return this.httpClient.delete<void>(`/api/vanilla/clients/addresses/${id}`);
  }

  saveClient(request: ClientRequest): Promise<{ id: number }> {
    return firstValueFrom(this.clientResourceService.saveClient(request));
  }

  getClientCompanies(params?: { clientId?: number }): Observable<CompanyModel[]> {
    return this.httpClient.get<CompanyModel[]>(`/api/vanilla/clients/companies`, {params: toHttpParams(params, true)});
  }

  getClientComments(clientId: number): Promise<CommentModel[]> {
    return firstValueFrom(this.clientResourceService.getClientComments(clientId));
  }

  buildRequest(client?: { client: ClientModel, additionalAddresses: AddressModel[], additionalContacts: ContactModel[] }): ClientRequest {
    let request: ClientRequest = { phone: ''};
    if (client) {
      request.id = client.client.id;
      request.phone = client.client.phone;
      request.birthDate = client.client.birthDate;
      request.countryId = client.client.countryId;
      request.fullname = client.client.fullname;
      request.additionalAddresses = client.additionalAddresses;
      request.additionalContacts = client.additionalContacts;
    }
    return request;
  }
}
