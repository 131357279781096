import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';
import { GisAdmDiv, GisItem } from './gis.service';

@Injectable({
  providedIn: 'root'
})
export class HxDgisService {

  private scriptLoaded = false;
  private scriptLoadPromise?: Promise<any>;

  constructor(private http: HttpClient,
              private toastr: ToastrService,
              private tr: TranslocoService) {
  }

  load(): Promise<void> {
    if (this.scriptLoaded) {
      return Promise.resolve((window as any).DG);
    } else {
      return this.loadScript('https://maps.api.2gis.ru/2.0/loader.js?pkg=full&lazy=true');
    }
  }

  private loadScript(url: string): Promise<void> {
    if (!this.scriptLoadPromise) {
      const self = this;
      this.scriptLoadPromise = new Promise<void>((resolve, reject) => {
        const script = document.createElement('script');
        script.innerHTML = '';
        script.src = url;
        script.async = false;
        script.defer = true;
        script.onload = () => {
          const DG = (window as any).DG;
          DG.then(() => {
            self.scriptLoaded = true;
            resolve();
          }, reject);
        };
        document.body.appendChild(script);
      });
    }

    return this.scriptLoadPromise;
  }

  getObjects(url: string, params: any): Promise<GisItem[]> {
    let httpParams = new HttpParams();
    Object.keys(params).forEach(key => {
      httpParams = httpParams.append(key, params[key]);
    });
    return firstValueFrom(this.http.get<DgisResponse>(url + `/search`, {params: httpParams})).then(res => {
      if (res.meta.code === 200) {
        return (res.result?.items ?? [])
          .filter(item => (item.address?.components?.length ?? 0) > 0)
          .map(item => this.convertToGisItem(item));
      } else if ([404, 400, 403, 500, 503].includes(res.meta.code)) {
        return [];
      }
      this.toastr.error(this.tr.translate('error.unexpected'));
      throw new Error('unexpected.2gis.error');
    });
  }

  find(url: string, name: string, params: any): Promise<GisItem[]> {
    let httpParams = new HttpParams();
    Object.keys(params).forEach(key => {
      httpParams = httpParams.append(key, params[key]);
    });
    httpParams = httpParams.append('q', name);
    return firstValueFrom(this.http.get<DgisResponse>(url + `/suggest`, {params: httpParams}))
      .then(res => res.result?.items ?? [])
      .then(items => items.filter(item => ['building', 'branch'].includes(item.type) && item.point && item.address_name && item.full_name)
        .map(item => this.convertToGisItem(item)));
  }

  private convertToGisItem(item: DgisItem): GisItem {
    if (!item.address_name || !item.type || !item.point || !item.full_name) {
      throw new Error('[address_name | type | point] is undefined');
    }
    const gisItem: GisItem = {
      id: item.id,
      type: item.type,
      name: item.name,
      fullname: item.full_name,
      addressName: item.address_name,
      point: item.point,
    };
    item.address?.components?.forEach(cmp => {
      if (cmp.type === 'street_number') {
        gisItem.street = cmp.street;
        gisItem.buildingNumber = cmp.number;
      } else if (cmp.type === 'number') {
        gisItem.buildingNumber = cmp.number;
      }
    });
    if (item.adm_div) {
      const divNameArr = item.adm_div
        .filter(div => !['country', 'region', 'city', 'district_area'].includes(div.type))
        .map(div => div.name);
      if (divNameArr.length > 0) {
        gisItem.admDivName = `${divNameArr.join(', ')}`;
      }
    }
    return gisItem;
  }
}

interface DgisResponse {
  meta: DgisMeta;
  result: DgisResult;
}

interface DgisMeta {
  api_version: string;
  code: number;
  issue_date?: string;
  error?: DgisError;
}

interface DgisError {
  type?: string;
  message?: string;
}

interface DgisResult {
  items: DgisItem[];
  total: number;
}

interface DgisItem {
  id: string;
  type: 'branch' | 'building';
  adm_div?: GisAdmDiv[];
  point?: { lat: number, lon: number };
  name?: string;
  full_name?: string;
  address_name?: string;
  address?: DgisAddress;
}

interface DgisBranch extends DgisItem {
  type: 'branch';
  purpose_name?: string;
}

interface DgisBuilding extends DgisItem {
  type: 'building';
  purpose_name: string;
  caption: string;
}

interface DgisAddress {
  building_code?: string;
  building_id?: string;
  components?: DgisStreetNumberComponent[] | DgisNumberComponent[] | DgisLocationComponent[];
}

interface DgisComponent {
  type: 'street_number' | 'number' | 'location';
}

interface DgisStreetNumberComponent extends DgisComponent {
  type: 'street_number';
  street: string;
  number: string;
  street_id?: string;
}

interface DgisNumberComponent extends DgisComponent {
  type: 'number';
  number: string;
}

interface DgisLocationComponent extends DgisComponent {
  type: 'location';
  comment: string;
}
