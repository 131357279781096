import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe Currency - show currency symbol
 */
@Pipe({
  name: 'time'
})
export class HxTimePipe implements PipeTransform {

  transform(value: string): string {
    if (value === undefined || value === null) {
      return '';
    }
    const splitArr = value.split(':');
    if (splitArr.length === 3 && splitArr[2] === '00') {
      return splitArr[0] + ':' + splitArr[1];
    }
    return value;
  }

}
