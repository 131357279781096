import { Pipe, PipeTransform } from '@angular/core';
import { Currency, DiscountResultModel, DiscountResultType } from '../vn-api';
import { getCurrencySymbol } from '../interface';

@Pipe({
  name: 'discountUoM'
})
export class HxDiscountUoMPipe implements PipeTransform {

  transform(discount?: DiscountResultModel, currency?: Currency): string {
    if (discount === undefined || discount === null) {
      return '';
    }
    if (discount.resultType === DiscountResultType.PERCENT_DISCOUNT) {
      return `${discount.promoValue} %`;
    } else if (discount.resultType === DiscountResultType.MONEY_DISCOUNT) {
      return `${discount.value} ${getCurrencySymbol(currency)}`;
    }
    return `${discount.value}`;
  }

}
