/**
 * Vanilla API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ReactionType = 'like' | 'dislike' | 'fire' | 'love' | 'lol' | 'sad';

export const ReactionType = {
    like: 'like' as ReactionType,
    dislike: 'dislike' as ReactionType,
    fire: 'fire' as ReactionType,
    love: 'love' as ReactionType,
    lol: 'lol' as ReactionType,
    sad: 'sad' as ReactionType
};

