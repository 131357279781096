import { Injectable } from '@angular/core';
import { CoinResourceService, SeekDirection } from '../vn-api';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HxCoinService {

  constructor(
    private coin: CoinResourceService
  ) {
  }

  calculateAppliedCoins(orderId: number, appliedAmount?: number): Promise<number> {
    return firstValueFrom(this.coin.calculateAppliedCoins(orderId, appliedAmount)).then(res => res.value);
  }

  getActiveCoins(params: { clientId: number; brandId: number; countryId: number; }) {
    return firstValueFrom(this.coin.getClientActiveCoins(params.clientId, params.brandId, params.countryId)).then(res => res.value);
  }

  getCoinTransaction(params: { clientId: number; brandId: number; countryId: number; seek?: string; seekDirection?: SeekDirection; limit?: number; }) {
    return firstValueFrom(this.coin.getCoinTransactions(params.clientId, params.brandId, params.countryId, params.limit, params.seek, params.seekDirection));
  }

}
