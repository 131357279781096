// core
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe Weight - show gramm (if vaule < 500) or kilogramm (value >= 500)
 */
@Pipe({
  name: 'weight'
})
export class HxWeightPipe implements PipeTransform {

  transform(value?: number): string {
    if (!value) {
      return '';
    }
    const HALF_KILO = 500;
    let newValue = `${value} гр`;

    if (value >= HALF_KILO) {
      newValue = value / 1000 + ' кг';
    }

    return newValue;
  }

}
