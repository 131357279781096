<ng-select [items]="options"
           bindValue="id"
           [placeholder]="'hx.user-search.placeholder.name' | transloco"
           [typeToSearchText]="'hx.user-search.placeholder.search' | transloco"
           [notFoundText]="'hx.user-search.placeholder.noFind' | transloco"
           [loadingText]="'hx.user-search.placeholder.loader' | transloco"
           [multiple]="multiple"
           [hideSelected]="true"
           [disabled]="disabled"
           [loading]="isLoading"
           [typeahead]="$searchChange"
           (ngModelChange)="onModelChanged($event)"
           [(ngModel)]="selected">
  <ng-template ng-label-tmp ng-option-tmp let-item="item">
    {{item.phone}} - {{item.lastname}} {{item.firstname}}
  </ng-template>
</ng-select>
