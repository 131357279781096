import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CityModel, HxCityService } from 'hx-services';

@Component({
  selector: 'hx-city-select',
  templateUrl: './city-select.component.html',
  styleUrls: ['./city-select.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => HxCitySelectComponent),
    multi: true
  }]
})
export class HxCitySelectComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() multiple = false;
  @Input() default = true;
  @Input() all = true;
  @Input() countryId?: number;
  @Output() selectChange = new EventEmitter<CityModel | CityModel[] | undefined>();

  disabled = false;
  options: CityModel[] = [];
  selected: number | number[] | undefined;

  constructor(
    private cityService: HxCityService,
  ) {
  }

  ngOnInit(): void {
    this.getOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['countryId']) {
      this.getOptions();
    }
  }

  onModelChanged(val: any) {
    this.onChange(val);
    if (!this.multiple) {
      if (this.selected) {
        this.selectChange.emit(this.options.find(p => p.id === this.selected));
      } else {
        this.selectChange.emit();
      }
    } else {
      if (Array.isArray(this.selected)) {
        const ids: number[] = this.selected;
        this.selectChange.emit(this.options.filter(p => ids.includes(p.id)));
      } else {
        this.selectChange.emit([]);
      }
    }
  }

  onChange(val: any) {
    // console.log('onChange called', val);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj?: number | number[]): void {
    this.selected = obj;
  }

  private getOptions() {
    if (this.all) {
      this.cityService.getAllCities({ limit: 1000, countryId: this.countryId }).then(cities => {
        this.options = cities;
        if ((this.options ?? []).length === 1) {
          this.selected = this.options[0].id;
          this.onModelChanged(this.selected);
        }
      });
    } else {
      this.cityService.getMyCities({ countryId: this.countryId }).subscribe(cities => {
        this.options = cities;
        if ((this.options ?? []).length === 1) {
          this.selected = this.options[0].id;
          this.onModelChanged(this.selected);
        }
      });
    }
  }
}
