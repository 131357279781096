import { Component, EventEmitter, forwardRef, Inject, Input, OnInit, Optional, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbDate, NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isBefore, parseISO, set } from 'date-fns';
import { isoDate } from 'hx-services';
import { HxDateParserFormatter, HxDatepickerLocale } from '../common';
import { HX_DATE_FORMAT, HX_DEFAULT_DATE_FORMAT } from '../datepicker';

@Component({
  selector: 'hx-date-select',
  templateUrl: './date-select.component.html',
  styleUrls: ['./date-select.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {provide: NgbDatepickerI18n, useClass: HxDatepickerLocale},
    {provide: NgbDateParserFormatter, useClass: HxDateParserFormatter},
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HxDateSelectComponent),
      multi: true
    }
  ]
})
export class HxDateSelectComponent implements OnInit, ControlValueAccessor {
  @ViewChild('d') datepicker!: NgbInputDatepicker;
  @Input() disabled = false;
  @Output() selectChange = new EventEmitter<string | undefined>();
  minDateParam: NgbDateStruct = {year: 1920, month: 1, day: 1};
  placeholder?: string;
  hasError = false;
  startDate: { year: number, month: number, day?: number } = {} as any;
  model?: NgbDateStruct;

  private _minDate: number | undefined;

  @Input()
  set minDate(val) {
    if (val) {
      const date = new Date(val);
      this.minDateParam = new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
    }
    this._minDate = val;
  }

  get minDate() {
    return this._minDate;
  }

  constructor(
    @Optional() @Inject(HX_DATE_FORMAT) private dateFormat: string,
    private ngbDateParserFormatter: NgbDateParserFormatter,
  ) {
    if (!this.dateFormat) {
      this.dateFormat = HX_DEFAULT_DATE_FORMAT;
    }
  }

  writeValue(obj: any): void {
    let val: NgbDate | undefined;
    if (obj && (typeof obj === 'string' || typeof obj === 'number')) {
      const dateStr = isoDate(obj);
      if (dateStr) {
        const date = parseISO(dateStr);
        val = new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
      }
    }
    this.setModel(val);
  }

  onChange(val: any) {
    // console.log('onChange called', val);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {

  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  ngOnInit(): void {
    this.placeholder = this.dateFormat.toLowerCase();
  }

  setToday() {
    const date = new Date();
    this.onDateSelected(new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate()));
    this.datepicker.close();
  }

  onDateSelected($event: NgbDateStruct) {
    this.datepicker.navigateTo({year: $event.year, month: $event.month});
    this.setModel($event);
    this.selectChange.emit(this.toIsoDate($event));
  }

  private toIsoDate(val?: NgbDateStruct): string | undefined {
    if (!val) {
      return undefined;
    }
    return isoDate(set(Date.now(), {year: val.year, month: val.month - 1, date: val.day}));
  }

  private setModel(val?: NgbDateStruct) {
    this.model = val;
    this.onChange(this.toIsoDate(this.model));
  }

  onModelChanged($event: any) {
    try {
      if (!$event) {
        this.setModel();
        return;
      }
      this.hasError = true;
      if (typeof $event !== 'string') {
        const ds = $event as NgbDateStruct;
        if (!this.minDateParam) {
          this.hasError = false;
          this.onDateSelected(ds);
        }
        if (!isBefore(new Date(ds.year, ds.month - 1, ds.day), new Date(this.minDateParam.year, this.minDateParam.month - 1, this.minDateParam.day))) {
          this.hasError = false;
          this.onDateSelected(ds);
        }
      }
    } catch (e) {
      console.warn(e);
    }
  }
}
