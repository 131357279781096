import { AddressModel, UiLabel } from '../../vn-api';
import { CityDeliveryMap, YaFeature } from '../../interface';

export abstract class GisService {

  constructor() {
  }

  abstract updateMarker(data: MapMarker, attr: GisAttributeInput): void;

  abstract showMap(url: string, attr: GisAttributeInput, clickHandler: MapClickHandler): Promise<void>;

  abstract showEmptyMap(url: string): Promise<void>;

  abstract initGisMap(url: string, attr: GisAttributeInput, clickHandler: MapClickHandler): Promise<void>;

  abstract initGisEmptyMap(url: string): Promise<void>;

  abstract searchStreets(term: string, url: string, param: { regionId?: number, bbox?: string, bounds?: string }, attr: GisAttributeInput): Promise<GisItem[]>;

  abstract clearMapData(): void;

  abstract destroyMap(): void;
}

export interface MapMarker {
  latitude: number;
  longitude: number;
  addressName: string;
}

export type MapClickHandler = (event: GisItem | undefined) => void;

export interface GisItem {
  id?: string;
  type?: string;
  name?: string;
  fullname?: string;
  point?: { lat: number, lon: number };
  addressName: string;
  purposeName?: string;
  street?: string;
  buildingNumber?: string;
  disabled?: boolean;
  admDivName?: string;
  hint?: string;
}

export interface GisStore {
  id: number;
  title: UiLabel;
}

export interface GisAttributeInput {
  storeDeliveryMap: Map<number, YaFeature[]>;
  store?: GisStore;
  stores: GisStore[];
  isAdmDivVisible: boolean;
  addressOptions: AddressModel[];
  cityDeliveryArea: CityDeliveryMap;
  gisItem?: GisItem;
}

export interface GisAdmDiv {
  name: string;
  type: string;
}
