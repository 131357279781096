import { TranslocoService } from '@ngneat/transloco';
import { Injectable } from '@angular/core';
import { GisAttributeInput, GisItem, GisService, MapClickHandler, MapMarker } from './gis.service';
import { HxDgisService } from './dgis.service';
import { getDeliveryStore, hasStoreDelivery } from './gis-util';
import { YaFeature, YaPolygon } from '../../interface';

@Injectable({
  providedIn: 'root'
})
export class HxDgisInfoService extends GisService {
  lastMarker: any;
  lastPopup: any;
  dgMap: any;
  DG: any;
  gisFields = 'items.point,items.adm_div,items.type,items.subtype,items.address';

  constructor(
    private tr: TranslocoService,
    private dgisService: HxDgisService,
  ) {
    super();
  }

  initGisMap(url: string, attr: GisAttributeInput, clickHandler: MapClickHandler): Promise<void> {
    const lat = attr.gisItem?.point?.lat;
    const lng = attr.gisItem?.point?.lon;
    const self = this;
    const dgVar = (window as any).DG;
    return self.DG.then(() => {
      self.dgMap = self.DG.map('map', {
        center: [attr.cityDeliveryArea?.center?.lat, attr.cityDeliveryArea?.center?.lng],
        zoom: 13
      });
      const polygons = self.DG.featureGroup();
      if (attr.store) {
        const features: YaFeature[] = attr.storeDeliveryMap.get(attr.store.id) ?? [];
        features.filter(feature => feature.geometry.type === 'Polygon')
          .forEach(feature => dgVar.polygon((feature.geometry as YaPolygon).coordinates[0], {
              label: feature.properties.description,
              fillColor: feature.properties.fill,
              color: feature.properties.stroke ?? 'gray'
            }).addTo(polygons)
          );
      } else if ((attr.stores ?? []).length > 0) {
        const features: YaFeature[] = Array.from(attr.storeDeliveryMap.values()).reduce((a, b) => a.concat(b), []);
        features.filter(feature => feature.geometry.type === 'Polygon')
          .forEach(feature => dgVar.polygon((feature.geometry as YaPolygon).coordinates[0], {
            label: feature.properties.description,
            fillColor: feature.properties.fill,
            color: feature.properties.stroke ?? 'gray'
          }).addTo(polygons));
      }

      // Добавление многоугольников в группу
      if (lat && lng && attr.gisItem) {
        if ((attr.store && hasStoreDelivery(lat, lng, attr.storeDeliveryMap, attr.store))
          || ((attr.stores ?? []).length > 0 && !!getDeliveryStore(lat, lng, attr.storeDeliveryMap, attr.stores))) {
          self.lastMarker = self.DG.marker([lat, lng]).addTo(this.dgMap);
          self.lastPopup = self.DG.popup();
          self.lastPopup.setLatLng([lat, lng])
            .setContent(attr.gisItem?.addressName).openOn(this.dgMap);
        } else {
          const text = self.tr.translate('error.no.store.delivery');
          self.lastPopup = self.DG.popup();
          self.lastPopup.setLatLng([lat, lng])
            .setContent(text).openOn(self.dgMap);
        }
      }
      // Добавление группы на карту
      polygons.addTo(self.dgMap);

      // Подстройка границ карты
      self.dgMap.fitBounds(polygons.getBounds());

      // @ts-ignore
      self.dgMap.on('click', e => {
        let latitude = e.latlng.lat;
        let longitude = e.latlng.lng;
        const cond1 = attr.store && hasStoreDelivery(latitude, longitude, attr.storeDeliveryMap, attr.store);
        const cond2 = (attr.stores ?? []).length > 0 && getDeliveryStore(latitude, longitude, attr.storeDeliveryMap, attr.stores) !== undefined;
        if (cond1 || cond2) {
          if (self.lastMarker) {
            self.lastMarker.removeFrom(self.dgMap);
          }
          self.lastMarker = self.DG.marker([latitude, longitude]).addTo(self.dgMap);
          self.dgisService.getObjects(url, {
            radius: 20,
            type: 'building',
            point: longitude + ',' + latitude,
            fields: this.gisFields
          }).then(res => {
            if (res.length > 0) {
              const gisItem: GisItem = res[0];
              clickHandler(gisItem);
              self.lastPopup = self.DG.popup();
              self.lastPopup.setLatLng([latitude, longitude])
                .setContent(gisItem.addressName).openOn(self.dgMap);
            } else {
              clickHandler(undefined);
              const text = self.tr.translate('error.dgis.address.notFound');
              self.lastPopup = self.DG.popup();
              self.lastPopup.setLatLng([latitude, longitude])
                .setContent(text).openOn(self.dgMap);
            }
          });
        } else {
          const text = self.tr.translate('error.no.store.delivery');
          if (self.lastMarker) {
            self.lastMarker.removeFrom(self.dgMap);
          }
          self.lastPopup = self.DG.popup();
          self.lastPopup.setLatLng([latitude, longitude])
            .setContent(text).openOn(self.dgMap);
        }
      });
    }).then(() => undefined);
  }

  searchStreets(term: string, url: string, param: { regionId?: number, bbox?: string }, attr: GisAttributeInput): Promise<GisItem[]> {
    console.log('[2gis] loadStreets', param.regionId);

    const params = {
      fields: this.gisFields,
      type: 'branch,building',
    };
    if (attr.cityDeliveryArea.center) {
      const points: number[][] = attr.cityDeliveryArea.deliveryMap.features.filter(feature => feature.geometry.type === 'Polygon')
        .map(f => (f.geometry as YaPolygon).coordinates[0]).reduce((a: number[][], b: number[][]) => a.concat(b), []);

      let maxLat: number;
      let maxLng: number;
      let minLat: number;
      let minLng: number;
      points.forEach(([lng, lat]) => {
        if (!maxLng) {
          maxLng = lng;
        } else if (lng > maxLng) {
          maxLng = lng;
        }
        if (!maxLat) {
          maxLat = lat;
        } else if (lat > maxLat) {
          maxLat = lat;
        }
        if (!minLng) {
          minLng = lng;
        } else if (minLng > lng) {
          minLng = lng;
        }
        if (!minLat) {
          minLat = lat;
        } else if (minLat > lat) {
          minLat = lat;
        }
      });
      // @ts-ignore
      params['polygon'] = `POLYGON((${minLat} ${minLng},${maxLat} ${minLng},${maxLat} ${maxLng},${minLat} ${maxLng}))`;
    }
    // @ts-ignore
    params['region_id'] = param.regionId;
    return this.dgisService.find(url, term, params).then(items => {
      items.forEach(item => {
        if (!item.point?.lat || !item.point.lon) {
          item.disabled = true;
        } else if (attr.store) {
          item.disabled = !hasStoreDelivery(item.point.lat, item.point.lon, attr.storeDeliveryMap, attr.store);
        } else if ((attr.stores ?? []).length > 0) {
          const deliveryStore = getDeliveryStore(item.point.lat, item.point.lon, attr.storeDeliveryMap, attr.stores);
          if (deliveryStore) {
            item.disabled = false;
          } else {
            item.disabled = true;
          }
        }
      });
      console.log('[2gis] load:', items);
      return items;
    }).catch(err => {
      console.error('[2gis] error', err);
      return [];
    });
  }

  updateMarker(data: MapMarker, attr: GisAttributeInput): void {
    console.debug('[2gis] updateMarker', data);
    if (!this.dgMap || !this.DG) {
      return;
    }
    const {latitude, longitude, addressName} = data;
    if ((attr.store && hasStoreDelivery(latitude, longitude, attr.storeDeliveryMap, attr.store))
      || (attr.stores ?? []).length > 0 && !!getDeliveryStore(latitude, longitude, attr.storeDeliveryMap, attr.stores)) {
      if (this.lastMarker) {
        this.lastMarker.removeFrom(this.dgMap);
      }
      this.lastMarker = this.DG.marker([latitude, longitude]).addTo(this.dgMap);
      this.lastPopup = this.DG.popup();
      this.lastPopup.setLatLng([latitude, longitude])
        .setContent(addressName).openOn(this.dgMap);
    } else {
      if (this.lastMarker) {
        this.lastMarker.removeFrom(this.dgMap);
      }
      const text = this.tr.translate('error.no.store.delivery');
      this.lastPopup = this.DG.popup();
      this.lastPopup.setLatLng([latitude, longitude])
        .setContent(text).openOn(this.dgMap);
    }
  }

  showMap(url: string, attr: GisAttributeInput, clickHandler: MapClickHandler): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dgisService.load().then(() => {
        // @ts-ignore
        this.DG = window['DG'];
        setTimeout(() => this.initGisMap(url, attr, clickHandler).then(resolve, reject), 100);
      }, reject);
    });
  }

  showEmptyMap(url: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dgisService.load().then(() => {
        // @ts-ignore
        this.DG = window['DG'];
        setTimeout(() => this.initGisEmptyMap(url).then(resolve, reject), 100);
      }, reject);
    });
  }

  initGisEmptyMap(url: string): Promise<void> {
    const self = this;
    const dgVar = (window as any).DG;
    return self.DG.then(() => {
      self.dgMap = self.DG.map('map', {
        center: [51.147174, 71.434167],
        zoom: 13
      });
    });
  }

  clearMapData(): void {
    if (this.lastMarker) {
      this.lastMarker.removeFrom(this.dgMap);
    }
    if (this.lastPopup) {
      this.lastPopup.remove();
    }
  }

  destroyMap() {
    if (this.dgMap) {
      this.dgMap.remove();
      this.dgMap = undefined;
    }
  }
}
