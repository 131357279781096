import { Pipe, PipeTransform } from '@angular/core';
import { abbrMap } from '../interface';

@Pipe({
  name: 'abbr'
})
export class HxAbbrPipe implements PipeTransform {

  transform(value?: string): string {
    if (value === undefined || value === null) {
      return '';
    }

    let result = value;
    abbrMap.forEach((shortVal, longVal) => {
      result = result.replace(longVal, shortVal);
    });
    return result;
  }
}
