import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BankAccountInfoModel, BankAccountRequest, ContractModel, ContractRequest, PagedList, SupplierFullModel, SupplierModel } from '../interface';
import { toHttpParams } from '../utils/globals';

@Injectable({
  providedIn: 'root'
})
export class HxSupplierService {
  constructor(
    private http: HttpClient,
  ) {
  }

  getSuppliers(params?: {
    page?: number,
    limit?: number,
    query?: string,
    storeIds?: number[],
    countryIds?: number[],
    organizationId?: number,
  }): Observable<PagedList<SupplierModel>> {
    return this.http.get<PagedList<SupplierModel>>(`/api/vanilla/suppliers`, {params: toHttpParams(params, true)});
  }

  getSupplierById(supplierId: number): Observable<SupplierFullModel> {
    return this.http.get<SupplierFullModel>(`/api/vanilla/suppliers/${supplierId}`);
  }

  saveSupplier(body: any): Observable<SupplierModel> {
    return this.http.post<SupplierModel>(`/api/vanilla/suppliers`, body);
  }

  removeSupplier(supplierId: number): Observable<any> {
    return this.http.delete(`/api/vanilla/suppliers/${supplierId}`);
  }

  // addresses
  getSupplierAddressesById(supplierId: number): Observable<any> {
    return this.http.get(`/api/vanilla/suppliers/${supplierId}/addresses`);
  }

  removeSupplierAddress(supplierId: number, addressId: number): Observable<any> {
    return this.http.delete(`/api/vanilla/suppliers/${supplierId}/addresses/${addressId}`);
  }

  createSupplierAddress(supplierId: number, body: any): Observable<any> {
    return this.http.post(`/api/vanilla/suppliers/${supplierId}/addresses`, body);
  }

  updateSupplierAddress(supplierId: number, addressId: number, body: any): Observable<any> {
    return this.http.put(`/api/vanilla/suppliers/${supplierId}/addresses/${addressId}`, body);
  }

  // supplier's bank
  getSupplierBankAccountList(supplierId: number): Observable<BankAccountInfoModel[]> {
    return this.http.get<BankAccountInfoModel[]>(`/api/vanilla/suppliers/${supplierId}/bank-accounts`);
  }

  removeSupplierBankAccount(supplierId: number, bankId: number): Observable<any> {
    return this.http.delete(`/api/vanilla/suppliers/${supplierId}/bank-accounts/${bankId}`);
  }

  saveSupplierBankAccount(supplierId: number, body: BankAccountRequest): Observable<any> {
    return this.http.post(`/api/vanilla/suppliers/${supplierId}/bank-accounts`, body);
  }

  // supplier's contract
  getSupplierContractList(supplierId: number): Observable<ContractModel[]> {
    return this.http.get<ContractModel[]>(`/api/vanilla/suppliers/${supplierId}/contracts`);
  }

  removeSupplierContract(supplierId: number, contractId: number): Observable<void> {
    return this.http.delete<void>(`/api/vanilla/suppliers/${supplierId}/contracts/${contractId}`);
  }

  createSupplierContract(supplierId: number, body: ContractRequest): Observable<ContractModel> {
    return this.http.post<ContractModel>(`/api/vanilla/suppliers/${supplierId}/contracts`, body);
  }

  updateSupplierContract(supplierId: number, contractId: number, body: ContractRequest): Observable<ContractModel> {
    return this.http.put<ContractModel>(`/api/vanilla/suppliers/${supplierId}/contracts/${contractId}`, body);
  }
}
