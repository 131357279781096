/**
 * Vanilla API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DiscountResultType = 'BONUS_PRODUCT' | 'FIXED_PRICE' | 'MONEY_DISCOUNT' | 'PERCENT_DISCOUNT';

export const DiscountResultType = {
    BONUS_PRODUCT: 'BONUS_PRODUCT' as DiscountResultType,
    FIXED_PRICE: 'FIXED_PRICE' as DiscountResultType,
    MONEY_DISCOUNT: 'MONEY_DISCOUNT' as DiscountResultType,
    PERCENT_DISCOUNT: 'PERCENT_DISCOUNT' as DiscountResultType
};

