import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { GisItem } from './gis.service';
import { HX_GOOGLE_MAPS_API_KEY } from '../../interface';

@Injectable({
  providedIn: 'root'
})
export class HxGoogleService {

  private scriptLoaded = false;
  private scriptLoadPromise?: Promise<any>;

  constructor(private http: HttpClient,
              @Optional() @Inject(HX_GOOGLE_MAPS_API_KEY) private googleMapsApiKey: string,) {
  }

  load(): Promise<void> {
    if (this.scriptLoaded) {
      return Promise.resolve();
    } else {
      return this.loadScript('https://maps.googleapis.com/maps/api/js?key=' + this.googleMapsApiKey + '&libraries=places&v=weekly');
    }
  }

  find(url: string, address: string, bounds?: string): Promise<GisItem[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('address', address);
    if (bounds) {
      httpParams = httpParams.append('bounds', bounds);
    }
    return firstValueFrom(this.http.get<google.maps.GeocoderResponse>(url + `/geocode`, {params: httpParams}))
      .then(res => res.results.map(res => this.convertToGisItem(res, bounds)));
  }

  private loadScript(url: string): Promise<void> {
    if (!this.scriptLoadPromise) {
      const self = this;
      this.scriptLoadPromise = new Promise<void>((resolve, reject) => {
        const script = document.createElement('script');
        script.innerHTML = '';
        script.src = url;
        script.async = false;
        script.defer = true;
        script.onload = () => {
          self.scriptLoaded = true;
          resolve();
        };
        script.onerror = reject;

        document.body.appendChild(script);
      });
    }

    return this.scriptLoadPromise;
  }

  convertToGisItem(feature: google.maps.GeocoderResult, bounds?: string): GisItem {
    const bds = bounds?.split('|') ?? [];
    const sw = bds[0].split(',');
    const ne = bds[1].split(',');
    const swJson = {lat: Number(ne[0]), lng: Number(ne[1])};
    const neJson = {lat: Number(sw[0]), lng: Number(sw[1])};
    const googleLatLngBounds = new google.maps.LatLngBounds(swJson, neJson);
    if (!googleLatLngBounds.contains(feature.geometry.location)) {
      throw new Error('google.bounds.error');
    }
    const houseNumberArr = feature.address_components.filter(r => r.types.includes(GoogleTypeCode.street_number)).map(c => c.short_name) ?? [];
    const streetNameArr = feature.address_components.filter(r => r.types.includes(GoogleTypeCode.route)).map(c => c.short_name) ?? [];
    if (houseNumberArr.length === 0 || streetNameArr.length === 0) {
      throw new Error('google.houseNumberOrStreetNumber.undefined');
    }
    const dgs: GisItem = {
      addressName: streetNameArr[0] + ' ' + houseNumberArr[0],
    };
    /*
    TODO в Атырау google не отрисован нормально, поэтому проверить нет возможности
    const admDivs: GisAdmDiv[] = [];
    feature.address_components.forEach(c => {
      c.types.forEach(t => admDivs.push({name: c.short_name, type: t}));
    });
    // dgs.admDivName = admDivs;
    */
    dgs.name = feature.formatted_address;
    dgs.fullname = feature.formatted_address;
    dgs.point = {lat: feature.geometry.location.lat(), lon: feature.geometry.location.lng()};
    return dgs;
  }
}

export enum GoogleStatusCode {
  OK = 'OK',
  ZERO_RESULTS = 'ZERO_RESULTS',
  OVER_DAILY_LIMIT = 'OVER_DAILY_LIMIT',
  OVER_QUERY_LIMIT = 'OVER_QUERY_LIMIT',
  REQUEST_DENIED = 'REQUEST_DENIED',
  INVALID_REQUEST = 'INVALID_REQUEST',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export enum GoogleTypeCode {
  street_address = 'street_address', //indicates a precise street address
  route = 'route', //indicates a named route (such as "US 101")
  intersection = 'intersection', //indicates a major intersection, usually of two major roads
  political = 'political', //indicates a political entity. Usually, this type indicates a polygon of some civil administration
  country = 'country', //indicates the national political entity, and is typically the highest order type returned by the Geocoder
  administrative_area_level_1 = 'administrative_area_level_1', //indicates a first-order civil entity below the country level. Within the United States, these administrative levels are states. Not all nations exhibit these administrative levels. In most cases, administrative_area_level_1 short names will closely match ISO 3166-2 subdivisions and other widely circulated lists; however this is not guaranteed as our geocoding results are based on a variety of signals and location data
  administrative_area_level_2 = 'administrative_area_level_2', //indicates a second-order civil entity below the country level. Within the United States, these administrative levels are counties. Not all nations exhibit these administrative levels.
  administrative_area_level_3 = 'administrative_area_level_3', //indicates a third-order civil entity below the country level. This type indicates a minor civil division. Not all nations exhibit these administrative levels.
  administrative_area_level_4 = 'administrative_area_level_4', //indicates a fourth-order civil entity below the country level. This type indicates a minor civil division. Not all nations exhibit these administrative levels.
  administrative_area_level_5 = 'administrative_area_level_5', //indicates a fifth-order civil entity below the country level. This type indicates a minor civil division. Not all nations exhibit these administrative levels.
  administrative_area_level_6 = 'administrative_area_level_6', //indicates a sixth-order civil entity below the country level. This type indicates a minor civil division. Not all nations exhibit these administrative levels.
  administrative_area_level_7 = 'administrative_area_level_7', //indicates a seventh-order civil entity below the country level. This type indicates a minor civil division. Not all nations exhibit these administrative levels.
  colloquial_area = 'colloquial_area', //indicates a commonly-used alternative name for the entity.
  locality = 'locality', //indicates an incorporated city or town political entity.
  sublocality = 'sublocality', //indicates a first-order civil entity below a locality. For some locations may receive one of the additional types: sublocality_level_1 to sublocality_level_5. Each sublocality level is a civil entity. Larger numbers indicate a smaller geographic area.
  neighborhood = 'neighborhood', //indicates a named neighborhood
  premise = 'premise', //indicates a named location, usually a building or collection of buildings with a common name
  subpremise = 'subpremise', //indicates a first-order entity below a named location, usually a singular building within a collection of buildings with a common name
  plus_code = 'plus_code', //indicates an encoded location reference, derived from latitude and longitude. Plus codes can be used as a replacement for street addresses in places where they do not exist (where buildings are not numbered or streets are not named). See https://plus.codes for details.
  postal_code = 'postal_code', //indicates a postal code as used to address postal mail within the country.
  natural_feature = 'natural_feature', //indicates a prominent natural feature.
  airport = 'airport', //indicates an airport.
  park = 'park', //indicates a named park.
  point_of_interest = 'point_of_interest', //indicates a named point of interest. Typically, these "POI"s are prominent local entities that don't easily fit in another category, such as "Empire State Building" or "Eiffel Tower".
  floor = 'floor', //indicates the floor of a building address.
  establishment = 'establishment', //typically indicates a place that has not yet been categorized.
  landmark = 'landmark', //indicates a nearby place that is used as a reference, to aid navigation.
  parking = 'parking', //indicates a parking lot or parking structure.
  post_box = 'post_box', //indicates a specific postal box.
  postal_town = 'postal_town', //indicates a grouping of geographic areas, such as locality and sublocality, used for mailing addresses in some countries.
  room = 'room', //indicates the room of a building address.
  street_number = 'street_number', //indicates the precise street number.
  bus_station = 'bus_station', //indicate the location of a bus, train or public transit stop.
  train_station = 'train_station', //indicate the location of a bus, train or public transit stop.
  transit_station = 'transit_station', //indicate the location of a bus, train or public transit stop.
}
