<hx-loader *ngIf="isLoading.receipt" [isLoading]="true"></hx-loader>
<div class="check" *ngIf="checkList && checkList.length > 0 && isView && !isLoading.receipt">
  <div class="text-right m--margin-top-20 m--margin-bottom-10">
    <button (click)="printList()" class="btn btn-outline-info m-btn m-btn--icon btn-sm">
    <span>
      <i class="la la-print"></i>
      <span>{{ 'hx.order-receipt.printCheck' | transloco }}</span>
    </span>
    </button>
  </div>
  <div *ngFor="let check of checkList">
    <header class="check__header">
      <img [src]="check.logoUrl" alt="" width="150" class="check__logo">

      <div class="check__print-date" style="margin-right: -10px;">
        <strong>{{ 'hx.order-receipt.checkHidden' | transloco }}:</strong><br>
        <br>{{ check.printDate | date: 'dd.MM.yyyy HH:mm' }}
      </div>
    </header>

    <div class="print-check__info">
      <div *ngIf="check.organizationTitle" class="print-check__info-line">
        {{ check.organizationTitle | uiLabel }}
      </div>

      <div *ngIf="check.organizationUin" class="print-check__info-line">
        {{ check.uinLabel }}
        <span>{{ check.organizationUin }}</span>
      </div>

      <div *ngIf="check.fiscalDetails?.type === 'webkassa' && check.webkassa" class="print-check__info-line">
        <span class="m--margin-left-0" *ngIf="check.webkassa.taxSeries">{{ 'hx.order-receipt.series' | transloco }} {{ check.webkassa.taxSeries }}</span>
        <span *ngIf="check.webkassa.taxNumber">№ {{ check.webkassa.taxNumber }}</span>
      </div>
    </div>

    <div class="check__content">
      <div class="check__line">
        <strong>{{ 'hx.order-receipt.check' | transloco }}:</strong>
        <span class="check__value">№{{ check.orderNumber }} <span *ngIf="isRefund(check)">({{ 'hx.order-receipt.return' | transloco }})</span></span>
      </div>

      <div class="check__line" *ngIf="isCashierVisible(check)">
        <strong>{{ 'hx.order-receipt.cashier' | transloco }}:</strong>
        <span class="check__value">{{ check.cashier | fio }}</span>
      </div>

      <div class="check__line" *ngIf="check.cancelReason">
        <strong>{{ 'hx.order-receipt.cancellation' | transloco }}:</strong>
        <span class="check__value">{{ check.cancelReason }}</span>
      </div>

      <div class="check__line" *ngIf="check.deliveryType === 'DELIVERY'">
        <strong>{{ 'hx.order-receipt.timeDelivery' | transloco }}:</strong>
        <span class="check__value">
          {{ check.orderDate | date: 'dd.MM.yyyy' }}
          <ng-container *ngIf="check.deliveryRange; else timeRef">
            ({{ check.deliveryRange.fromTime | time }}-{{ check.deliveryRange.toTime | time  }})
          </ng-container>
          <ng-template #timeRef>
            ({{check.orderDate | date: 'HH:mm'}})
          </ng-template>
          <ng-container *ngIf="check.courierShortName">-{{ check.courierShortName }}</ng-container>
        </span>
      </div>

      <div class="check__line" *ngIf="check.deliveryType === 'PICKUP'">
        <strong>{{ 'hx.order-receipt.timePickup' | transloco }}:</strong>
        <span class="check__value">{{ check.orderDate | date: 'dd.MM.yyyy HH:mm' }}</span>
      </div>

      <div class="check__line" *ngIf="check.clientPhone">
        <strong>{{ 'hx.order-receipt.client' | transloco }}:</strong>
        <span class="check__value">{{ clientInfo(check) }}</span>
      </div>

      <div class="check__line" *ngIf="check.recipientPhone">
        <strong>{{ 'hx.order-receipt.user' | transloco }}:</strong>
        <span class="check__value">{{ recipientInfo(check) }}</span>
      </div>

      <div class="check__line" *ngIf="check.deliveryType === 'DELIVERY'">
        <strong>{{ 'hx.order-receipt.address' | transloco }}:</strong>
        <span class="check__value">{{ check.address }}</span>
      </div>

      <div class="check__line" *ngIf="printNote && check.note">
        <strong>{{ 'hx.order-receipt.note' | transloco }}:</strong>
        <span class="check__value">{{ check.note }}</span>
      </div>

      <table class="check__table">
        <thead>
        <tr>
          <td>{{ 'hx.order-receipt.table.head.name' | transloco }}</td>
          <td class="text-center text-nowrap">{{ 'hx.order-receipt.table.head.weight' | transloco }}</td>
          <td class="text-center text-nowrap">{{ 'hx.order-receipt.table.head.num' | transloco }}</td>
          <td class="text-right text-nowrap">{{ 'hx.order-receipt.table.head.sum' | transloco }}</td>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let product of check.products">
          <td style="max-width: 150px;word-wrap: break-word;">
            {{ product.title | uiLabel }}
            <span *ngIf="product.priceAmount !== 1">{{ product.priceAmount }}</span>
          </td>
          <td class="text-center text-nowrap">
            {{ product.weight | weight }}
          </td>
          <td class="text-center text-nowrap">
            {{ product.amount }}
          </td>
          <td class="text-right text-nowrap">
            {{ product.value?.toLocaleString('ru-RU') }}
          </td>
        </tr>
        <tr>
          <td>
            <strong>{{ 'hx.order-receipt.table.body.total' | transloco }}:</strong>
          </td>
          <td class="text-center"></td>
          <td class="text-center"></td>
          <td class="text-right text-nowrap">
            <strong>{{ check.subTotal?.toLocaleString('ru-RU') }}</strong>
          </td>
        </tr>

        <tr *ngIf="check.discounts?.length">
          <td colspan="5">
            <strong>{{ 'hx.order-receipt.table.body.discount' | transloco }}:</strong>
          </td>
        </tr>

        <tr *ngFor="let discount of check.discounts">
          <ng-container *ngIf="discount.enabled">
            <td class="m--padding-left-5">
              {{ discount.promoTitle | uiLabel }}
            </td>
            <td class="text-center">
            </td>
            <td class="text-center">
              {{ discount.productAmount }}
            </td>
            <td class="text-right text-nowrap">
              {{ discount | discountUoM: check.currency }}
            </td>
          </ng-container>
        </tr>
        <tr class="check__total" *ngIf="check.total">
          <td>
            <strong>{{ 'hx.order-receipt.table.body.totalAll' | transloco }}:</strong>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td class="text-right text-nowrap">
            <strong>{{ check.total?.toLocaleString('ru-RU') }} {{currencySymbol}}</strong>
          </td>
        </tr>
        <tr *ngIf="check.total > 0 && check.webkassa?.taxType === 'VAT'">
          <td>
            {{ 'hx.order-receipt.table.body.text' | transloco }}
          </td>
          <td class="text-center">
          </td>
          <td class="text-center">
          </td>
          <td class="text-right text-nowrap">
            {{ getVAT(check.total) }} {{currencySymbol}}
          </td>
        </tr>
        </tbody>
      </table>

      <div *ngIf="check.paid">
        <strong>{{ 'hx.order-receipt.paidMethod' | transloco }}:</strong>

        <div *ngFor="let pt of check.payments" class="check__paymentType">
          {{'paymentType.' + pt.type | transloco}} <strong>{{ (pt.value)?.toLocaleString('ru-RU') }} {{currencySymbol}}</strong>
        </div>
      </div>

      <!-- WEBKASSA fiscal details -->
      <ng-container *ngIf="check.fiscalDetails?.type === 'webkassa' && check.webkassa">
        <div class="print-check-wk">
          <div class="print-col">
            <div class="check__line">
              <span class="check__label">{{ 'hx.receipt.fiscalCheck' | transloco }}</span>
            </div>

            <div class="check__line">
              <span class="check__label">{{ 'hx.receipt.wkFiscalNumber' | transloco }}:</span>
              <span class="check__value">{{ check.fiscalNumber }}</span>
            </div>

            <div class="check__line">
              <span class="check__label">{{ 'hx.receipt.wkCashboxIdentificationNumber' | transloco }}:</span>
              <span class="check__value">{{ check.webkassa.cashboxIdentificationNumber }}</span>
            </div>

            <div class="check__line">
              <span class="check__label">{{ 'hx.receipt.wkCashboxFactoryNumber' | transloco }}:</span>
              <span class="check__value">{{ check.webkassa.cashboxFactoryNumber }}</span>
            </div>

            <div class="check__line">
              <span class="check__label">{{ 'hx.receipt.wkCashboxRegistrationNumber' | transloco }}:</span>
              <span class="check__value">{{ check.webkassa.cashboxRegistrationNumber }}</span>
            </div>

            <div class="check__line">
              <span class="check__label">{{ 'hx.receipt.wkFiscalDate' | transloco }}:</span>
              <span class="check__value">{{ check.fiscalDate | date: 'dd.MM.yyyy HH:mm': check.storeTimezone }}</span>
            </div>
          </div>

          <div *ngIf="check.payQR" class="check-qr">
            <img [src]="check.payQR" alt="">
          </div>

          <div *ngIf="check.fiscalQR" class="print-col fiscal-qr" style="float: right; margin-top: 10px;">
            <img [src]="check.fiscalQR" alt="">
          </div>
        </div>

        <div class="print-check__info" *ngIf="check.offlineMode">
          <div class="print-check__offline_mode">
            <div>{{ 'hx.receipt.offlineMode' | transloco }}</div>
          </div>
        </div>

        <div class="print-check__info">
          <div class="print-check__ofd_verifier">
            <div>{{ check.ofdVerifierInfo }}</div>
          </div>
        </div>
      </ng-container>

      <!-- ATOL fiscal details -->
      <ng-container *ngIf="check.fiscalDetails?.type === 'atol' && check.atol">
        <div class="print-check-wk">
          <div class="print-col">
            <div class="check__line">
              <span class="check__label">{{ 'hx.receipt.fiscalization' | transloco }}</span>
            </div>

            <div class="check__line">
              <span class="check__label">{{ 'hx.order-receipt.text-1' | transloco }}:</span>
              <!-- серийный номер кассы -->
              <span class="check__value">{{check.atol.serialNumber}}</span>
            </div>

            <div class="check__line">
              <span class="check__label">{{ 'hx.order-receipt.text-2' | transloco }}:</span>
              <span class="check__value">{{ check.atol.registrationNumber }}</span>
            </div>

            <div class="check__line">
              <span class="check__label">{{ 'hx.order-receipt.text-3' | transloco }}:</span>
              <span class="check__value">{{ check.atol.fnNumber }}</span>
            </div>

            <div class="check__line">
              <span class="check__label">{{ 'hx.order-receipt.text-4' | transloco }}:</span>
              <span class="check__value">{{ check.atol.fiscalDocumentNumber }}</span>
            </div>

            <div class="check__line">
              <span class="check__label">{{ 'hx.order-receipt.text-5' | transloco }}:</span>
              <span class="check__value">{{ check.atol.fiscalDocumentSign }}</span>
            </div>

            <div class="check__line">
              <span class="check__label">{{ 'hx.order-receipt.text-6' | transloco }}:</span>
              <span class="check__value">{{ check.atol.fiscalReceiptNumber }}</span>
            </div>

            <div class="check__line">
              <span class="check__label">{{ 'hx.order-receipt.text-7' | transloco }}:</span>
              <span class="check__value">{{ check.atol.fiscalDocumentDateTime | date: 'dd.MM.yyyy HH:mm': check.storeTimezone }}</span>
            </div>

            <div class="check__line">
              <span class="check__label">{{ 'hx.order-receipt.text-8' | transloco }}:</span>
              <span class="check__value">{{ check.atol.shiftNumber }}</span>
            </div>

            <div class="check__line">
              <span class="check__label">{{ 'hx.order-receipt.text-9' | transloco }}:</span>
              <span class="check__value">{{ check.atol.fnsUrl }}</span>
            </div>

            <div *ngIf="check.atol?.taxationType" class="check__line">
              <span class="check__label">{{ 'hx.order-receipt.text-11' | transloco }}:</span>
              <span class="check__value">{{ 'hx.atol.taxationType.' + check.atol.taxationType | transloco }}</span>
            </div>
          </div>

          <div *ngIf="check.payQR" class="check-qr">
            <img [src]="check.payQR" alt="">
          </div>

          <div *ngIf="check.fiscalQR" class="print-col fiscal-qr" style="float: right; margin-top: 10px;">
            <img [src]="check.fiscalQR" alt="">
          </div>
        </div>

        <div class="print-check__info">
          <div class="print-check__ofd_verifier">
            <div>{{ check.ofdVerifierInfo }}</div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="check__footer">
      <div class="check__contacts">
        <div class="check__info">
          {{ check.storeAddress }}
        </div>

        <div class="check__info">Call-Center {{ check.callCenterPhones?.join(', ') }}</div>

        <div class="check__title">{{ 'hx.order-receipt.thanks' | transloco }}</div>
      </div>

      <div class="check__copyright m--margin-bottom-10">
       {{ 'hx.order-receipt.footer' | transloco }}
      </div>
    </div>

    <div *ngIf="check.withoutReceipt" class="check__without_receipt">
      {{ 'hx.order-receipt.noCheck' | transloco }}
    </div>
  </div>
  <div class="text-right m--margin-top-20 m--margin-bottom-10">
    <button (click)="printList()" class="btn btn-outline-info m-btn m-btn--icon btn-sm">
    <span>
      <i class="la la-print"></i>
      <span>{{ 'hx.order-receipt.printCheck' | transloco }}</span>
    </span>
    </button>
  </div>
</div>
<ng-container *ngFor="let page of timesToPrintList">
<div id="print-body" *ngIf="checkList && checkList.length > 0 && !isView">
  <div class="print-check" *ngFor="let check of checkList">
    <div class="print-check__container">
      <header class="print-check__header">
        <img [src]="check.logoUrl" alt="" width="110" class="print-check__logo">

        <div class="print-check__print-date">
          <span>{{ 'hx.order-receipt.printedCheck' | transloco }}:</span>
          <br> {{ check.printDate | date: 'dd.MM.yyyy HH:mm' }}
          <br> {{ check.printman }}
        </div>
      </header>

      <div class="print-check__info">
        <div *ngIf="check.organizationTitle" class="print-check__info-line">
          {{ check.organizationTitle | uiLabel }}
        </div>

        <div *ngIf="check.organizationUin" class="print-check__info-line">
          {{ check.uinLabel }}
          <span>{{ check.organizationUin }}</span>
        </div>

        <div *ngIf="check.fiscalDetails?.type === 'webkassa' && check.webkassa" class="print-check__info-line">
          <span *ngIf="check.webkassa.taxSeries" class="m--margin-left-0">{{ 'hx.order-receipt.text-10' | transloco }} {{ check.webkassa.taxSeries }}</span>
          <span *ngIf="check.webkassa.taxNumber">№ {{ check.webkassa.taxNumber }}</span>
        </div>
      </div>

      <div class="print-check__contacts m--margin-bottom-5">
        <div class="check__line">
          <strong>{{ 'hx.order-receipt.text-6' | transloco }}:</strong>
          <span class="check__value">№{{ check.orderNumber }} <span *ngIf="isRefund(check)">({{ 'hx.order-receipt.return' | transloco }})</span></span>
        </div>

        <div class="check__line" *ngIf="isCashierVisible(check)">
          <strong>{{ 'hx.order-receipt.cashier' | transloco }}:</strong>
          <span class="check__value">{{ check.cashier | fio }}</span>
        </div>

        <div class="check__line" *ngIf="check.cancelReason">
          <strong>{{ 'hx.order-receipt.cancellation' | transloco }}:</strong>
          <span class="check__value">{{ check.cancelReason }}</span>
        </div>

        <div class="check__line" *ngIf="check.deliveryType === 'DELIVERY'">
          <strong>{{ 'hx.order-receipt.timeDelivery' | transloco }}:</strong>
          <span class="check__value">
            {{ check.orderDate | date: 'dd.MM.yyyy' }}
            <ng-container *ngIf="check.deliveryRange; else timeRef">
              ({{ check.deliveryRange.fromTime | time }}-{{ check.deliveryRange.toTime | time  }})
            </ng-container>
            <ng-template #timeRef>
              ({{check.orderDate | date: 'HH:mm'}})
            </ng-template>
            <span [ngbTooltip]="'hx.order-receipt.ng.courier' | transloco" *ngIf="check.courierShortName">{{ check.courierShortName }}</span>
          </span>
        </div>

        <div class="check__line" *ngIf="check.deliveryType === 'PICKUP'">
          <strong>{{ 'hx.order-receipt.timePickup' | transloco }}:</strong>
          <span class="check__value">{{ check.orderDate | date: 'dd.MM.yyyy HH:mm' }}</span>
        </div>

        <div class="check__line" *ngIf="check.clientPhone">
          <strong>{{ 'hx.order-receipt.client' | transloco }}:</strong>
          <span class="check__value">{{ clientInfo(check) }}</span>
        </div>

        <div class="check__line" *ngIf="check.recipientPhone">
          <strong>{{ 'hx.order-receipt.user' | transloco }}:</strong>
          <span class="check__value">{{ recipientInfo(check) }}</span>
        </div>

        <div class="check__line" *ngIf="check.deliveryType === 'DELIVERY'">
          <strong>{{ 'hx.order-receipt.address' | transloco }}:</strong>
          <span class="check__value">{{check.address}}</span>
        </div>

        <div class="check__line" *ngIf="printNote && check.note">
          <strong>{{ 'hx.order-receipt.note' | transloco }}:</strong>
          <span class="check__value">{{ check.note }}</span>
        </div>
      </div>

      <table class="print-check__table">
        <thead>
        <tr>
          <td>{{ 'hx.order-receipt.table.head.name' | transloco }}</td>
          <td class="text-center text-nowrap">{{ 'hx.order-receipt.table.head.weight' | transloco }}</td>
          <td class="text-center text-nowrap">{{ 'hx.order-receipt.table.head.num' | transloco }}</td>
          <td class="text-right text-nowrap">{{ 'hx.order-receipt.table.head.sum' | transloco }}</td>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let product of check.products">
          <td style="max-width: 150px;word-wrap: break-word;">
            {{ product.title | uiLabel }} <span *ngIf="product.priceAmount !== 1">{{ product.priceAmount }}</span>
          </td>

          <td class="text-center text-nowrap">
            {{ product.weight | weight }}
          </td>

          <td class="text-center text-nowrap">
            {{ product.amount }}
          </td>

          <td class="text-right text-nowrap">
            {{ product.value?.toLocaleString('ru-RU') }}
          </td>
        </tr>
        <tr>
          <td>
            <strong>{{ 'hx.order-receipt.table.body.total' | transloco }}:</strong>
          </td>
          <td class="text-center"></td>
          <td class="text-center"></td>
          <td class="text-right">
            <strong>{{ check.subTotal?.toLocaleString('ru-RU') }} {{currencySymbol}}</strong>
          </td>
        </tr>

        <tr *ngIf="check.discounts?.length">
          <td colspan="5">{{ 'hx.order-receipt.table.body.discount' | transloco }}:</td>
        </tr>

        <tr *ngFor="let discount of check.discounts">
          <ng-container *ngIf="discount.enabled">
            <td class="m--padding-left-5">
              {{ discount.promoTitle | uiLabel }}
            </td>
            <td class="text-center">
            </td>
            <td class="text-center">
              {{ discount.productAmount }}
            </td>
            <td class="text-right text-nowrap">
              {{ discount | discountUoM: check.currency }}
            </td>
          </ng-container>
        </tr>

        <tr *ngIf="check.total">
          <td>
            <strong>{{ 'hx.order-receipt.table.body.totalAll' | transloco }}:</strong>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td class="text-right text-nowrap">
            <strong>{{ check.total?.toLocaleString('ru-RU') }} {{currencySymbol}}</strong>
          </td>
        </tr>
        <tr *ngIf="check.total && check.webkassa?.taxType === 'VAT'">
          <td>
            {{ 'hx.order-receipt.table.body.text' | transloco }}
          </td>
          <td class="text-center">
          </td>
          <td class="text-center">
          </td>
          <td class="text-right text-nowrap">
            {{ getVAT(check.total) }} {{currencySymbol}}
          </td>
        </tr>
        </tbody>
      </table>

      <div class="check-qr" *ngIf="check.payQR">
        <img [src]="check.payQR" alt="">
      </div>

      <div *ngIf="check.paid" class="print-check__payment-wrap">
        <span class="print-check__subtitle">
          {{ 'hx.order-receipt.paidMethod' | transloco }}:
        </span>

        <div *ngFor="let pt of check.payments" class="print-check__payment">
          <span>{{'paymentType.' + pt.type | transloco}}</span>
          <span>{{ (pt.value)?.toLocaleString('ru-RU') }} {{currencySymbol}}</span>
        </div>
      </div>

      <ng-container *ngIf="check.fiscalDetails?.type === 'webkassa' && check.webkassa">
        <div class="print-check-wk">
          <div class="print-check__info print-col">
            <div class="print-check__info-line">
              {{ 'hx.receipt.fiscalCheck' | transloco }}
            </div>

            <div class="print-check__info-line">
              {{ 'hx.receipt.wkFiscalNumber' | transloco }}: {{ check.fiscalNumber }}
            </div>

            <div class="print-check__info-line">
              {{ 'hx.receipt.wkCashboxIdentificationNumber' | transloco }}: {{ check.webkassa.cashboxIdentificationNumber }}
            </div>

            <div class="print-check__info-line">
              {{ 'hx.receipt.wkCashboxFactoryNumber' | transloco }}: {{ check.webkassa.cashboxFactoryNumber }}
            </div>

            <div class="print-check__info-line">
              {{ 'hx.receipt.wkCashboxRegistrationNumber' | transloco }}: {{ check.webkassa.cashboxRegistrationNumber }}
            </div>

            <div class="print-check__info-line">
              {{'hx.receipt.wkFiscalDate' | transloco}}: {{ check.fiscalDate | date: 'dd.MM.yyyy HH:mm': check.storeTimezone }}
            </div>
          </div>

          <div *ngIf="check.payQR" class="check-qr">
            <img [src]="check.payQR" alt="">
          </div>

          <div *ngIf="check.fiscalQR" class="print-col fiscal-qr" style="float:right;">
            <img [src]="check.fiscalQR" alt="">
          </div>
        </div>

        <div class="print-check__offline_mode" *ngIf="check.offlineMode">
          <div>{{ 'hx.receipt.offlineMode' | transloco }}</div>
        </div>

        <div class="print-check__info">
          <div class="print-check__ofd_verifier">
            <div>{{ check.ofdVerifierInfo }}</div>
          </div>
        </div>
      </ng-container>

      <!-- ATOL fiscal details -->
      <ng-container *ngIf="check.fiscalDetails?.type === 'atol' && check.atol">
        <div class="print-check-wk">
          <div class="print-check__info print-col">
            <div class="print-check__info-line">
              {{ 'hx.receipt.fiscalization' | transloco }}
            </div>

            <div class="print-check__info-line">
              {{ 'hx.order-receipt.text-1' | transloco }}: {{check.atol.serialNumber}}
            </div>

            <div class="print-check__info-line">
              {{ 'hx.order-receipt.text-2' | transloco }}: {{ check.atol.registrationNumber }}
            </div>

            <div class="print-check__info-line">
              {{ 'hx.order-receipt.text-3' | transloco }}: {{ check.atol.fnNumber }}
            </div>

            <div class="print-check__info-line">
              {{ 'hx.order-receipt.text-4' | transloco }}: {{ check.atol.fiscalDocumentNumber }}
            </div>

            <div class="print-check__info-line">
              {{ 'hx.order-receipt.text-5' | transloco }}: {{ check.atol.fiscalDocumentSign }}
            </div>

            <div class="print-check__info-line">
              {{ 'hx.order-receipt.text-6' | transloco }}: {{ check.atol.fiscalReceiptNumber }} {{ 'hx.order-receipt.text-8' | transloco }}: {{ check.atol.shiftNumber }}
            </div>

            <div class="print-check__info-line">
              {{ 'hx.order-receipt.text-7' | transloco }}: {{ check.atol.fiscalDocumentDateTime | date: 'dd.MM.yyyy HH:mm': check.storeTimezone }}
            </div>

            <div class="print-check__info-line">
              {{ 'hx.order-receipt.text-9' | transloco }}: {{ check.atol.fnsUrl }}
            </div>

            <div *ngIf="check.atol?.taxationType" class="print-check__info-line">
              {{ 'hx.order-receipt.text-11' | transloco }}: {{ 'hx.atol.taxationType.' + check.atol.taxationType | transloco }}
            </div>
          </div>

          <div *ngIf="check.payQR" class="check-qr">
            <img [src]="check.payQR" alt="">
          </div>

          <div *ngIf="check.fiscalQR" class="print-col fiscal-qr" style="float: right; margin-top: 10px;">
            <img [src]="check.fiscalQR" alt="">
          </div>
        </div>

        <div class="print-check__info">
          <div class="print-check__ofd_verifier">
            <div>{{ check.ofdVerifierInfo }}</div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="check__footer">
      <div class="check__contacts">
        <div class="check__info">
          {{ check.storeAddress }}
        </div>

        <div class="check__info">Call-Center {{ check.callCenterPhones?.join(', ') }}</div>
        <div class="check__title">{{ 'hx.order-receipt.paid' | transloco }}</div>
      </div>

      <div class="check__copyright m--margin-bottom-10">
        {{ 'hx.order-receipt.footer' | transloco }}
      </div>
    </div>

    <div *ngIf="check.withoutReceipt" class="check__without_receipt">
      {{ 'hx.order-receipt.noCheck' | transloco }}
    </div>
  </div>
</div>
</ng-container>
