import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PagedList, RejectionFullModel, RejectionModel } from '../interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { toHttpParams } from '../utils/globals';

@Injectable({
  providedIn: 'root'
})
export class HxRejectionService {
  private rejectionSubject = new BehaviorSubject<RejectionModel | undefined>(undefined);
  readonly rejectionObs = this.rejectionSubject.asObservable();

  private storeId?: number;
  private cityId?: number;
  private date?: string;
  private rejection?: RejectionFullModel;
  private orderId?: number;
  constructor(private httpClient: HttpClient) {
  }

  initRejection(opts: {storeId?: number, cityId?: number, date?: string, orderId?: number}): void {
    this.storeId = opts.storeId;
    this.cityId = opts.cityId;
    this.date = opts.date;
    this.orderId = opts.orderId;
  }

  setRejection(rejection: RejectionFullModel): void {
    this.rejection = rejection;
  }

  getRejectionList(params?: {
    date?: string;
    fromTime?: string;
    toTime?: string;
    cityId?: number;
    storeId?: number;
    page?: number;
    limit?: number;
    deliveryExists?: boolean;
    pickupExists?: boolean;
    decorExists?: boolean;
    promoExists?: boolean;
    query?: string;
  }): Observable<PagedList<RejectionFullModel>> {
    return this.httpClient.get<PagedList<RejectionFullModel>>(`/api/vanilla/rejections`, {params: toHttpParams(params, true)});
  }

  saveRejection(request: RejectionModel | undefined): Observable<RejectionFullModel> {
    if (this.rejection) {
      return this.httpClient.put<RejectionFullModel>(`/api/vanilla/rejections/${this.rejection.id}`, request);
    } else {
      return this.httpClient.post<RejectionFullModel>(`/api/vanilla/rejections`, request);
    }
  }

  prepareRejection(request: Partial<RejectionModel>
  ) {
    request.date = this.date;
    request.storeId = this.storeId;
    request.cityId = this.cityId;
    request.orderId = this.orderId;
    this.rejectionSubject.next(request as RejectionModel);
  }

  clearRejection() {
    this.rejectionSubject.next(undefined);
    this.storeId = undefined;
    this.cityId = undefined;
    this.date = undefined;
    this.rejection = undefined;
  }

  getStoreId(): number | undefined {
    return this.storeId;
  }

  getCityId(): number | undefined {
    return this.cityId;
  }

  getDate(): string | undefined {
    return this.date;
  }

  getOrderId(): number | undefined {
    return this.orderId;
  }
}
