import { Component, EventEmitter, forwardRef, Inject, Input, OnInit, Optional, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbDate, NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { endOfISOWeek, endOfMonth, endOfQuarter, set, startOfISOWeek, startOfMonth, startOfQuarter, subMonths, subQuarters, subWeeks } from 'date-fns';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateRange, isoDate } from 'hx-services';
import { HxDateParserFormatter, HxDatepickerLocale } from '../common';
import { HX_DATE_FORMAT, HX_DEFAULT_DATE_FORMAT } from '../datepicker';

@Component({
  selector: 'hx-date-range-select',
  templateUrl: './date-range-select.component.html',
  styleUrls: ['./date-range-select.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {provide: NgbDateParserFormatter, useClass: HxDateParserFormatter},
    {provide: NgbDatepickerI18n, useClass: HxDatepickerLocale}, {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HxDateRangeSelectComponent),
      multi: true
    }
  ]
})
export class HxDateRangeSelectComponent implements OnInit, ControlValueAccessor {
  @ViewChild('dateRangePicker') dateRangePicker!: NgbInputDatepicker;
  @Input() maxMonthRange?: number;
  @Input() maxWeekRange?: number;
  @Output() selectChange = new EventEmitter<DateRange | undefined>();
  fromDate?: NgbDateStruct | null;
  toDate?: NgbDateStruct | null;
  hoveredDate?: NgbDateStruct | null;
  placeholder?: string;
  isDisabled = false;
  hasError = false;
  startDate: { year: number, month: number, day?: number } = {} as any;
  viewModel?: string;
  initialChange = true;
  maxDate!: NgbDateStruct;
  minDate!: NgbDateStruct;

  constructor(
    @Optional() @Inject(HX_DATE_FORMAT) private dateFormat: string,
    private ngbDateParserFormatter: NgbDateParserFormatter,
  ) {
    if (!this.dateFormat) {
      this.dateFormat = HX_DEFAULT_DATE_FORMAT;
    }
  }

  writeValue(val: any): void {
    if (val) {
      if (val.from && val.to && (typeof val.from === 'string' || typeof val.from === 'number') && (typeof val.to === 'string' || typeof val.to === 'number')) {
        // should come ISO dates or millis
        const fromDate = new Date(val.from);
        const toDate = new Date(val.to);
        this.fromDate = new NgbDate(fromDate.getFullYear(), fromDate.getMonth() + 1, fromDate.getDate());
        this.toDate = new NgbDate(toDate.getFullYear(), toDate.getMonth() + 1, toDate.getDate());
        this.resetMinMaxDates();
        this.updateModel(false);
      }
    } else {
      this.fromDate = undefined;
      this.toDate = undefined;
      this.updateModel(false);
    }
  }

  onChange(val: any) {
    // console.log('onChange called', val);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {

  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  ngOnInit(): void {
    this.placeholder = this.dateFormat.toLowerCase() + '/' + this.dateFormat.toLowerCase();
  }

  onDateSelected(date: NgbDate): void {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && (date.after(this.fromDate) || date.equals(this.fromDate))) {
      this.toDate = date;
    } else {
      this.toDate = undefined;
      if (this.maxMonthRange) {
        this.minDate = date;
        this.maxDate = new NgbDate(date.year, date.month + this.maxMonthRange, date.day);
      }
      if (this.maxWeekRange) {
        this.minDate = date;
        this.maxDate = new NgbDate(date.year, date.month, date.day + (this.maxWeekRange * 7));
      }
      this.fromDate = date;
    }
    if (this.fromDate && this.toDate) {
      this.updateModel();
      this.dateRangePicker.close();
    }
  }

  isHovered(date: NgbDate): boolean {
    return this.fromDate !== undefined && this.toDate === undefined
      && this.hoveredDate !== undefined && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate): boolean {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate): boolean {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

  setLastWeek() {
    const fromDate = startOfISOWeek(subWeeks(new Date(), 1));
    const toDate = endOfISOWeek(subWeeks(new Date(), 1));
    this.setDates(fromDate, toDate);
  }

  setLastMonth() {
    const fromDate = startOfMonth(subMonths(new Date(), 1));
    const toDate = endOfMonth(subMonths(new Date(), 1));
    this.setDates(fromDate, toDate);
  }

  setLastQuarter() {
    const fromDate = startOfQuarter(subQuarters(new Date(), 1));
    const toDate = endOfQuarter(subQuarters(new Date(), 1));
    this.setDates(fromDate, toDate);
  }

  setThisWeek() {
    const fromDate = startOfISOWeek(new Date());
    const toDate = endOfISOWeek(new Date());
    this.setDates(fromDate, toDate);
  }

  setThisMonth() {
    const fromDate = startOfMonth(new Date());
    const toDate = endOfMonth(new Date());
    this.setDates(fromDate, toDate);
  }

  setThisQuarter() {
    const fromDate = startOfQuarter(new Date());
    const toDate = endOfQuarter(new Date());
    this.setDates(fromDate, toDate);
  }

  apply() {
    if (this.fromDate && this.toDate) {
      this.updateModel();
      this.dateRangePicker.close();
    }
  }

  onViewModelChanged() {
    this.hasError = true;
    try {
      if (this.viewModel?.includes('/')) {
        const [fromStr, toStr] = this.viewModel.split('/');
        const fromDate = this.ngbDateParserFormatter.parse(fromStr);
        const toDate = this.ngbDateParserFormatter.parse(toStr);
        if (fromDate && toDate) {
          const now = Date.now();
          const fd = set(now, {year: fromDate.year, month: fromDate.month - 1, date: fromDate.day});
          const td = set(now, {year: toDate.year, month: toDate.month - 1, date: toDate.day});
          if (td.getTime() >= fd.getTime()) {
            this.hasError = false;
            this.fromDate = fromDate;
            this.toDate = toDate;
            this.updateModel();
          }
        }
      }
    } catch (e) {
    }
  }

  private setDates(fromDate: Date, toDate: Date): void {
    this.fromDate = this.toNgbDate(fromDate);
    this.toDate = this.toNgbDate(toDate);
    this.dateRangePicker.navigateTo({year: this.fromDate.year, month: this.fromDate.month});
  }

  private toNgbDate(date: Date): NgbDate {
    return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
  }

  private toIsoDate(val?: NgbDateStruct): string | undefined {
    if (!val) {
      return undefined;
    }
    return isoDate(set(Date.now(), {year: val.year, month: val.month - 1, date: val.day}));
  }

  private updateModel(emit = true) {
    if (this.fromDate && this.toDate) {
      const fromDateStr = this.ngbDateParserFormatter.format(this.fromDate);
      const toDateStr = this.ngbDateParserFormatter.format(this.toDate);
      this.startDate = this.fromDate;
      this.viewModel = `${fromDateStr}/${toDateStr}`;
      this.onChange({from: this.toIsoDate(this.fromDate), to: this.toIsoDate(this.toDate)});
      if (emit) {
        this.selectChange.emit({from: this.toIsoDate(this.fromDate), to: this.toIsoDate(this.toDate)});
      }
    } else {
      this.viewModel = undefined;
      this.onChange(undefined);
      if (emit) {
        this.selectChange.emit(undefined);
      }
    }
  }

  private resetMinMaxDates() {
    this.minDate = {year: 1970, month: 1, day:1};
    this.maxDate = {year: 2100, month: 1, day:1};
  }
}
