import { Injectable } from '@angular/core';
import { ReactionModel, ReactionRequest, ReactionResourceService } from '../vn-api';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HxReactionService {

  constructor(
    private reaction: ReactionResourceService
  ) {
  }

  saveReaction(request: ReactionRequest): Promise<ReactionModel> {
    return firstValueFrom(this.reaction.saveReaction(request));
  }
}
