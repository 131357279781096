/**
 * Vanilla API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ActorType = 'CLIENT' | 'USER' | 'SYSTEM' | 'ACCOUNTANT_USER';

export const ActorType = {
    CLIENT: 'CLIENT' as ActorType,
    USER: 'USER' as ActorType,
    SYSTEM: 'SYSTEM' as ActorType,
    ACCOUNTANT_USER: 'ACCOUNTANT_USER' as ActorType
};

