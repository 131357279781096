<div class="input-group" [ngClass]="{'error': hasError}">
  <input [disabled]="disabled" [minDate]="minDateParam" [(ngModel)]="model" class="form-control" [placeholder]="placeholder"
         (ngModelChange)="onModelChanged($event)"
         [footerTemplate]="footerTemplate"
         (dateSelect)="onDateSelected($event)"
         ngbDatepicker #d="ngbDatepicker">
  <div class="input-group-append">
    <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="w-4 h-4 fill-current" style="height: 12px; width: 12px;">
        <path d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"></path>
      </svg>
    </button>
  </div>
</div>

<ng-template #footerTemplate>
  <hr class="my-0">
  <button [disabled]="disabled" class="btn btn-primary btn-sm m-2 float-left" (click)="setToday()">{{ 'hx.data-select.btn.today' | transloco }}</button>
  <button class="btn btn-secondary btn-sm m-2 float-right" (click)="d.close()">{{ 'hx.data-select.btn.close' | transloco }}</button>
</ng-template>
