import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { HxAuthService } from 'hx-services';

@Component({
  selector: 'hx-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.css']
})
export class HxLanguageSelectComponent implements OnInit {
  @Input() language!: string;
  @Output() selectLang = new EventEmitter<string>();

  flagIcon!: string;

  constructor(
    private tr: TranslocoService,
    private auth: HxAuthService,
  ) {
  }

  ngOnInit() {
    this.language = this.auth.getUiLang();
    if (this.language == 'kk') {
      this.flagIcon = `fi fi-kz`;
    } else if (this.language == 'ru') {
      this.flagIcon = `fi fi-ru`;
    } else if (this.language == 'en') {
      this.flagIcon = `fi fi-us`;
    }
    this.tr.setActiveLang(this.language);
  }

  onModelChanged(lang: string) {
    this.language = lang;
    this.auth.setUiLang(lang).then(() => {
      location.reload();
    });
  }
}
