<div class="m-subheader" *transloco="let tr; read 'ord'">
  <div class="d-flex align-items-center">
    <div class="mr-auto">
      <h3 class="m-subheader__title m-subheader__title--separator">
        <a *ngIf="!isCart && isCallcenter" (click)="toggleImportant()" [ngbTooltip]="order.important ? tr('btn.setImportant.tooltip') : tr('btn.setNotImportant.tooltip')">
          <i class="la" [ngClass]="{'la-star': order.important, 'la-star-o': !order.important}"></i>
        </a>
        {{tr('order')}}
      </h3>
      <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
        <li class="m-nav__item m-nav__item--home">
          <a routerLink="/" class="m-nav__link m-nav__link--icon">
            <i class="m-nav__link-icon la la-home"></i>
          </a>
        </li>
        <li class="m-nav__separator">
          -
        </li>
        <li class="m-nav__item">
          <a (click)="goBack()" class="m-nav__link">
            <span class="m-nav__link-text">
              {{tr(isArchive ? 'orderArchive' : 'orderAll')}}
            </span>
          </a>
        </li>
        <li class="m-nav__separator">
          -
        </li>
        <li class="m-nav__item">
          <a routerLink="./" class="m-nav__link">
            <span class="m-nav__link-text">
              #{{order.uniqueNumber}}
            </span>
          </a>
        </li>
      </ul>
    </div>

    <div>
      <div *ngIf="!isArchive">
        <ng-content></ng-content>
      </div>

      <div *ngIf="isArchive && !isCallcenter">
        <a (click)="unarchive(order.id)" class="btn btn-outline-primary m-btn m-btn--icon m--margin-right-10">
          <span>
            <i class="la la-folder-open"></i>
            <span>{{ tr('unarchive') }}</span>
          </span>
        </a>
        <span class="m-badge m-badge--metal m-badge--wide m-badge--rounded m--icon-font-size-lg4 archive-badge">{{tr('archive')}}</span>
      </div>
    </div>
  </div>
</div>

<div class="m-content" *transloco="let tr; read 'ord'">
  <!--Begin::Main Portlet-->
  <div class="row">
    <div class="col-sm-12">
      <div class="m-portlet m-portlet--head-solid-bg" [ngClass]="headerClass">
        <div class="row">
          <div class="col-lg-8">
            <div class="m-portlet__head">
              <div class="m-portlet__head-caption">
                <div class="m-portlet__head-title">
                  <h3 class="m-portlet__head-text">
                    #{{order.uniqueNumber}} {{ tr('order-info.from') }} {{ order.createDate | date:'dd.MM.yyyy HH:mm' : store?.timezone }}
                  </h3>
                </div>
              </div>

              <div class="m-portlet__head-tools">
                <ul class="m-portlet__nav">
                  <li class="m-portlet__nav-item">
                    <span class="m-portlet__head-text" *ngIf="status === 'ACTIVE'">{{ countDown | countDown }} | </span>
                    <span [class]="'m-badge m-badge--' + statusMap.get(order.action) + ' m-badge--wide m-badge--rounded'">
                    {{ 'action.' + order.action | transloco }}
                  </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="hc-source-header">
              <span *ngIf="order.from">
                {{tr('source')}}: {{ 'from.' + order.from | transloco }}
              </span>
              <a *ngIf="order.parentOrderId" [routerLink]="'/orders/' + order.parentOrderId" class="hc-source-link">
                {{tr('parentOrder')}}
              </a>
            </div>
          </div>
        </div>
        <div class="m-portlet__body">
          <div class="row">
            <div class="col-xl-8 col-lg-12">
              <table class="table m--margin-bottom-0">
                <thead class="thead-default">
                <tr>
                  <th>{{tr('info')}}</th>
                  <th class="text-right">
                    <ng-container *ngIf="order.clientType === 'LEGAL'">
                      <i class="la la-exclamation-triangle"></i> {{tr('forLegal')}}
                    </ng-container>
                  </th>
                </tr>
                </thead>
              </table>

              <div class="is--responsive-table">
                <table class="table">
                  <thead>
                  <tr>
                    <th>{{tr('client')}} <span *ngIf="order.recipientClient">| {{tr('recipient')}}</span></th>
                    <th>
                      <span *ngIf="order.deliveryType === 'DELIVERY'">{{tr('deliveryAddress')}}</span>
                      <span *ngIf="order.deliveryType === 'PICKUP'">{{tr('store')}}</span>
                    </th>
                    <th style="width: 12rem;">
                      <span *ngIf="order.deliveryRange">
                        {{tr('deliveryTime')}}
                      </span>
                      <span *ngIf="!order.deliveryRange">
                        {{tr('pickupTime')}}
                      </span>
                    </th>
                    <th>{{tr('payment')}}</th>
                    <th class="text-right" style="width: 5rem;">{{tr('total')}}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>
                      <div *ngIf="order.phone && order.phone !== order.client?.phone">{{ order.phone }},</div>
                      <a (click)="showClientModal(order.client)"><span [ngbTooltip]="tr('client')">{{ order.client?.phone }}</span></a> <br>
                      <span [ngbTooltip]="tr('client')">{{ order.client?.fullname }}</span> <span *ngIf="order.recipientClient"> |</span><br>
                      <span *ngIf="order.recipientClient" [ngbTooltip]="tr('recipient')">{{ order.recipientClient.phone }}</span><br>
                      <span *ngIf="order.recipientClient" [ngbTooltip]="tr('recipient')">{{ order.recipientClient.fullname }}</span> <br>
                      <hx-client-indicator [clientId]="order.clientId"></hx-client-indicator>
                      <a *ngIf="letterOfAuthorityUrl" [href]="letterOfAuthorityUrl" target="_blank">{{tr('letterOfAuthority')}}</a>
                    </td>
                    <td>
                      <span *ngIf="order.deliveryType === 'PICKUP' && store">
                        <i class="las la-store-alt"></i> {{ store.title | uiLabel }} ({{ store.city.title | uiLabel }})
                        <hx-managers-info [ngbTooltip]="tr('managersInfo')" [groupId]="store.id"></hx-managers-info><br>
                      </span>
                      <ng-container *ngIf="order.deliveryType === 'DELIVERY'">
                        <i class="la la-truck"></i>
                        {{ order.address }} <ng-container *ngIf="order.admDiv">({{order.admDiv}})</ng-container>
                        <span *ngIf="order.entrance" [ngbTooltip]="tr('address.entrance')">{{tr('address.entrance.abbr')}}: {{ order.entrance }}, </span>
                        <span *ngIf="order.floor">{{tr('address.floor')}}: {{ order.floor }}, </span>
                        <span *ngIf="order.flat">{{tr('address.flat.abbr')}}: {{ order.flat }}</span>
                        <span *ngIf="order.addressNote">, {{ order.addressNote }}</span>
                        <div *ngIf="store">
                          <i class="las la-store-alt"></i> {{ store.title | uiLabel }} ({{ store.city.title | uiLabel }})
                          <hx-managers-info [ngbTooltip]="tr('managersInfo')" [groupId]="store.id"></hx-managers-info>
                        </div>
                      </ng-container>
                    </td>
                    <td>
                      <div style="vertical-align: middle;" *ngIf="!order.deliveryRange">
                        <i class="la la-clock-o"></i><strong>{{ order.date | date: 'dd.MM.yyyy HH:mm' }}</strong>
                      </div>

                      <div *ngIf="order.deliveryRange" style="vertical-align: middle;">
                        <i class="la la-clock-o"></i>
                        <strong>
                          {{ order.deliveryRange.date | date: 'dd.MM.yyyy' }}
                          <br>
                          ({{ order.deliveryRange.fromTime | time }}-{{ order.deliveryRange.toTime | time }})
                        </strong>
                      </div>

                      <div *ngIf="order.deliveryUser">
                        <div>{{tr('courier')}}:</div>
                        <i *ngIf="order.deliveryUser.fullname" class="la la-user"></i> {{ order.deliveryUser.fullname }} <br>
                        <i *ngIf="order.deliveryUser.phone" class="la la-phone"></i> {{ order.deliveryUser.phone }} <br>
                      </div>
                    </td>
                    <td>
                    <span *ngIf="order.paid">
                      {{tr('paid')}}
                    </span>
                      <ng-container *ngIf="order.payment?.payments">
                        <div *ngFor="let pt of order.payment.payments">
                          <i [hxPaymentIcon]="pt.type" [ngbTooltip]="'paymentType.' + pt.type | transloco"></i>
                          {{'paymentType.' + pt.type | transloco}} {{ pt.value | uiCurrency:order.currency }}
                        </div>
                      </ng-container>
                    </td>
                    <td class="text-nowrap">
                      <h6 class="text-right">
                        {{ order.total | uiCurrency:order.currency }}
                      </h6>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <table class="table m--margin-bottom-0">
                <thead class="thead-default">
                <tr>
                  <th>{{tr('contents')}}</th>

                  <th class="text-right no-margin-label">
                    <label for="toggleAllProducts" style="vertical-align: middle;">
                      {{tr('showAll')}}
                    </label>

                    <span class="m-switch m-switch--icon m-switch--outline m-switch--success">
                    <label>
                      <input type="checkbox" checked="checked" id="toggleAllProducts" [(ngModel)]="showAllProducts">
                      <span></span>
                    </label>
                  </span>
                  </th>
                </tr>
                </thead>
              </table>

              <div class="is--responsive-table">
                <table class="table">
                  <thead>
                  <tr>
                    <th>{{tr('product.title')}}</th>
                    <th class="text-right">{{tr('product.price')}}</th>
                    <th class="text-right">{{tr('product.weight')}}</th>
                    <th class="text-right">{{tr('product.amount')}}</th>
                    <th class="text-right">{{tr('product.sum')}}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <ng-container *ngFor="let product of order.products">
                    <ng-container *ngIf="isAvailableProduct(product.status)">
                      <tr [ngClass]="{'m--text-line-through': isCancelledProduct(product.status, product.payment) }">
                        <td>
                          <span *ngIf="'REFUNDED' === product.status" [routerLink]="'/orders/' + product.containerId">{{ product.productInfo.title | uiLabel }}</span>
                          <span *ngIf="'REFUNDED' !== product.status">{{ product.productInfo.title | uiLabel }}</span>
                          <span *ngIf="'CART' === product.status"> ({{ tr('order-info.basket') }}) </span>
                          <span *ngIf="product.price?.amount !== 1"> {{ product.price?.amount }}</span>
                          <span
                            *ngIf="!['CANCELLED', 'REFUNDED'].includes(order.action) && ['CANCELLED', 'REFUNDED'].includes(product.status)"
                            [class]="'m-badge m-badge--' + statusMap.get(product.status) + ' m-badge--wide m-badge--rounded m--margin-left-10'">
                          {{ 'product.status.' + product.status | transloco }}
                        </span>
                        </td>
                        <td class="text-right text-nowrap">
                          {{ product.price?.value | uiCurrency:order.currency }}
                        </td>
                        <td class="text-right text-nowrap">
                        <span *ngIf="product.weight">
                          {{ product.weight | weight }}
                        </span>
                        </td>
                        <td class="text-right">
                          {{ product.amount }}
                        </td>
                        <td class="text-right text-nowrap">
                          <i class="la la-certificate" [ngbTooltip]="tr('order-info.discount')" *ngIf="isDiscounted(product.id)"></i>
                          {{ product.value | uiCurrency:order.currency }}
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>
                  <tr>
                    <td>
                      <span [ngClass]="{'m--font-bolder m--regular-font-size-lg2': order.total === order.subTotal}">{{tr('product.total')}}</span>
                    </td>
                    <td class="text-right"></td>
                    <td class="text-right text-nowrap">
                      {{ totalGram | weight }}
                    </td>
                    <td class="text-right">
                      {{ totalSAmount }}
                    </td>
                    <td class="text-right text-nowrap">
                      <h5 class="m--font-success" [ngClass]="{'m--font-normal': discounts.length}">
                        {{ order.subTotal | uiCurrency:order.currency }}
                      </h5>
                    </td>
                  </tr>

                  <tr *ngIf="discounts.length">
                    <td colspan="5">{{tr('discounts')}}:</td>
                  </tr>

                  <tr [ngClass]="{'is--disabled': !discount.enabled}" *ngFor="let discount of discounts">
                    <td class="m--padding-left-20">
                      {{ discount.promoTitle | uiLabel }}
                    </td>
                    <td class="text-right">
                    </td>
                    <td class="text-right">
                    </td>

                    <td class="text-right">
                      {{ discount.productAmount }}
                    </td>

                    <td class="text-right">
                      <h5 class="m--font-info m--font-normal">
                        <!-- TODO @deedarb #ins other result types ? -->
                        <span *ngIf="discount.resultType === 'PERCENT_DISCOUNT'">{{ discount.promoValue }} %</span>
                        <span *ngIf="discount.resultType === 'MONEY_DISCOUNT'">{{ discount.value | uiCurrency: order.currency }}</span>
                      </h5>
                    </td>
                  </tr>

                  <tr *ngIf="isTotalShow()">
                    <td>
                      <h5>{{tr('finalTotal')}}</h5>
                    </td>
                    <td class="text-right">
                    </td>
                    <td class="text-right text-nowrap">
                      {{ totalGram | weight }}
                    </td>
                    <td class="text-right">
                      {{ totalSAmount }}
                    </td>
                    <td class="text-right text-nowrap">
                      <h5 class="m--font-success">
                        {{ order.total | uiCurrency: order.currency }}
                      </h5>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <hx-order-refunded-list [orderId]="order.id"></hx-order-refunded-list>

              <table class="table m--margin-bottom-0" *ngIf="decorFileCount > 0">
                <thead class="thead-default">
                <tr>
                  <th>{{tr('files')}}</th>
                </tr>
                </thead>
              </table>

              <div class="m-widget4 m-widget4--padding" *ngIf="decorFileCount > 0">
                <div class="m-widget4__item" *ngFor="let file of order.files">
                  <ng-container *ngIf="file.folder === 'DECOR'">
                    <a class="m-widget4__img m-widget4__img--icon" (click)="showImage(file)"
                       [ngbTooltip]="file.fileName + ' ' +  tr('file.tooltip', {createDate: (file.createDate | date: 'dd.MM.yyyy HH:mm')})">
                      <img *ngIf="file.mimeType !== 'application/pdf'" [src]="fileDownloadUrl + '/' + file.guid">
                      <i *ngIf="file.mimeType === 'application/pdf'" class="la la-file-pdf-o la-2x"></i>
                    </a>
                    <div class="m-widget4__info">
                      <span class="m-widget4__text">{{ file.fileName }}</span>
                    </div>
                  </ng-container>
                </div>
              </div>

              <table *ngIf="paymentOrderFileCount > 0" class="table m--margin-bottom-0">
                <thead class="thead-default">
                <tr>
                  <th>{{tr('paymentOrder')}}</th>
                </tr>
                </thead>
              </table>

              <div *ngIf="paymentOrderFileCount > 0" class="m-widget4 m-widget4--padding">
                <div class="m-widget4__item" *ngFor="let file of order.files">
                  <ng-container *ngIf="file.folder === 'PAYMENT_ORDER'">
                    <span class="m-widget4__img m-widget4__img--icon" (click)="showPdf(file.guid)">
                      <i class="la la-file-pdf-o la-2x"></i>
                    </span>

                    <div class="m-widget4__info">
                      <span class="m-widget4__text" (click)="showPdf(file.guid)">
                        {{tr('viewPdf')}}
                      </span>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>

            <div class="col-xl-4 col-lg-12">
              <ng-container *ngIf="hasAudioPermission">
                <table class="table">
                  <thead class="thead-default">
                  <tr>
                    <th>{{tr('callRecord')}}</th>
                  </tr>
                  </thead>
                </table>

                <div class="m--margin-bottom-20">
                  <div *ngIf="order.calls.length">
                    <div class="m--margin-bottom-10" *ngFor="let call of order.calls">
                      <div class="m--margin-bottom-5">
                        {{ call.date | date: 'dd.MM HH:mm' : store?.timezone }}, {{ call.phone }}
                      </div>
                      <a *ngIf="call.audioRecord" target="_blank" [href]="call.audioRecord">{{tr('listenRecord')}}</a>
                      <audio *ngIf="call.audioRecord" preload="none" controls>
                        <source [src]="call.audioRecord">
                      </audio>
                    </div>
                  </div>

                  <div *ngIf="!order.calls.length">
                    {{tr('callRecord.notFound')}}
                  </div>
                </div>
              </ng-container>

              <div *ngIf="order.note">
                <table class="table">
                  <thead class="thead-default">
                  <tr>
                    <th>{{tr('note')}}</th>
                  </tr>
                  </thead>
                </table>
                <p>{{ order.note }}</p>
              </div>

              <div class="m--margin-top-20" *ngIf="order.decorText">
                <table class="table">
                  <thead class="thead-default">
                  <tr>
                    <th>{{tr('decorText')}}</th>
                  </tr>
                  </thead>
                </table>
                <p>{{ order.decorText }}</p>
              </div>

              <table class="table">
                <thead class="thead-default">
                <tr>
                  <th>{{tr('additionalInfo')}}</th>
                </tr>
                </thead>
              </table>
              <div class="m-list-timeline" *ngIf="store">
                <span *ngIf="order.from">
                  {{tr('from')}} {{ 'from.' + order.from | transloco }}
                </span>
                <span [ngbTooltip]="tr('referrer.tooltip')" style="float: right; font-weight: 900;">{{referrerEntry?.title | uiLabel}}</span>
                <hx-order-event-list [id]="order.id" [timezone]="store.timezone" [eventClickFn]="onEventClickedFn"></hx-order-event-list>
              </div>

              <table class="table">
                <thead class="thead-default">
                <tr>
                  <th>{{tr('btn.receipt')}}</th>
                </tr>
                </thead>
              </table>

              <button (click)="showCheck()" class="btn btn-outline-info m-btn m-btn--icon btn-block">
                <span>
                  <i class="la la-file-text"></i>
                  <span>{{tr('btn.receipt')}}</span>
                </span>
              </button>
            </div>
          </div>

          <div *ngIf="isLoading.order"
            class="m-page-loader m-page-loader--non-block m-page-loader--relative">
            <div class="m-loader m-loader--brand"></div>
          </div>
        </div>
      </div>

      <a routerLink="/orders/list" [queryParams]="{activeCart: false}">
        ← {{tr('backToList')}}
      </a>
    </div>
  </div>
  <!--End::Main Portlet-->
</div>

<div *ngIf="hasFeedbackPermission">
  <hx-feedback-list [orderId]="order.id"></hx-feedback-list>
</div>

<div>
  <hx-comment-list [orderId]="order.id"></hx-comment-list>
</div>

<ng-template #templateImage let-modal>
  <ng-container *ngIf="image">
    <div class="modal-header">
      <h3 class="modal-title">{{ image.name }}</h3>
      <button class="close" type="button" (click)="closeModal(modal)">
        <span>×</span>
      </button>
    </div>

    <div class="modal-body text-center">
      <iframe *ngIf="image.mimeType === 'application/pdf'" class="iframe" width="100%" height="100%" [src]="image.path" frameborder="0"></iframe>
      <img *ngIf="image.mimeType !== 'application/pdf'" [src]="image.path" [alt]="image.name" style="max-width: 100%;">
    </div>
  </ng-container>
</ng-template>

<ng-template #templatePdf let-modal *transloco="let tr; read 'ord'">
  <div class="modal-header">
    <h3 class="modal-title">
      {{tr('paymentOrder')}}
    </h3>

    <button class="close" type="button" (click)="closeModal(modal)">
      <span>×</span>
    </button>
  </div>

  <div class="modal-body text-center" *ngIf="pdfUrl">
    <iframe class="iframe" width="100%" height="100%" [src]="pdfUrl" frameborder="0"></iframe>
  </div>
</ng-template>

<hx-app-order-check></hx-app-order-check>
