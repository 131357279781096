import { Injectable } from '@angular/core';
import { GisAttributeInput, GisItem, GisService, MapClickHandler, MapMarker } from './gis.service';
import { TranslocoService } from '@ngneat/transloco';
import { HxGoogleService } from './google.service';
import { getDeliveryStore, hasStoreDelivery } from './gis-util';
import { YaFeature, YaPolygon } from '../../interface';

@Injectable({
  providedIn: 'root'
})
export class HxGoogleInfoService extends GisService {
  private infoWindows: google.maps.InfoWindow[] = [];
  private map?: google.maps.Map;

  constructor(
    private tr: TranslocoService,
    private googleService: HxGoogleService,
  ) {
    super();
  }

  clearMapData(): void {
    this.closeAllInfoWindows();
  }

  destroyMap(): void {
    // no destroy map exists, just reuse variable
  }

  initGisMap(url: string, attr: GisAttributeInput, clickHandler: MapClickHandler): Promise<void> {
    const lat = attr.gisItem?.point?.lat;
    const lng = attr.gisItem?.point?.lon;
    const center: google.maps.LatLngLiteral = {
      lat: attr.cityDeliveryArea.center?.lat || 0,
      lng: attr.cityDeliveryArea.center?.lng || 0
    };
    const renderMarkerFn = () => {
      // Добавление многоугольников в группу
      if (lat && lng && attr.gisItem) {
        if ((attr.store && hasStoreDelivery(lat, lng, attr.storeDeliveryMap, attr.store)) || ((attr.stores ?? []).length > 0
          && !!getDeliveryStore(lat, lng, attr.storeDeliveryMap, attr.stores))) {
          this.showInfoWindow(attr.gisItem.addressName, new google.maps.LatLng(lat, lng));
        } else {
          this.noStoreDeliveryMsg(new google.maps.LatLng(lat, lng));
        }
      }
    };
    if (this.map) {
      this.map.setCenter(center);
      renderMarkerFn();
      return Promise.resolve();
    }
    this.map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
      center,
      zoom: 11,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        mapTypeIds: ['roadmap', 'satellite'],
      },
    });
    const polygons: any[] = [];
    if (attr.store) {
      const features: YaFeature[] = attr.storeDeliveryMap.get(attr.store.id) ?? [];
      features.forEach(feature => polygons.push(new google.maps.Polygon({
        paths: (feature.geometry as YaPolygon).coordinates[0].map((point: any) => ({lat: point[0], lng: point[1]})),
        geodesic: false,
        fillColor: feature.properties.fill,
        strokeColor: feature.properties.stroke ?? 'gray',
        strokeOpacity: 1.0,
        strokeWeight: 1,
        map: this.map
      })));
    } else if ((attr.stores ?? []).length > 0) {
      const features: YaFeature[] = Array.from(attr.storeDeliveryMap.values()).reduce((a, b) => a.concat(b), []);
      features.forEach(feature => {
        const polygon = new google.maps.Polygon({
          paths: (feature.geometry as YaPolygon).coordinates[0].map((point: any) => ({lat: point[0], lng: point[1]})),
          geodesic: false,
          fillColor: feature.properties.fill,
          strokeColor: feature.properties.stroke ?? 'gray',
          strokeOpacity: 1.0,
          strokeWeight: 1,
          map: this.map
        });
        polygon.addListener('click', (e: any) => {
          this.closeAllInfoWindows();
          if ((attr.store && hasStoreDelivery(e.latLng.lat(), e.latLng.lng(), attr.storeDeliveryMap, attr.store))
            || ((attr.stores ?? []).length > 0 && !!getDeliveryStore(e.latLng.lat(), e.latLng.lng(), attr.storeDeliveryMap, attr.stores))) {
            const geocoder: google.maps.Geocoder = new google.maps.Geocoder();
            geocoder.geocode({location: e.latLng}).then(result => {
              console.log('[google] geocoder result', result);
              if (result.results.length > 0) {
                const res: google.maps.GeocoderResult = result.results[0];
                const gisItem = this.googleService.convertToGisItem(res, attr.cityDeliveryArea.bounds);
                clickHandler(gisItem);
                this.showInfoWindow(res.formatted_address, e.latLng);
              } else {
                clickHandler(undefined);
                this.showInfoWindow(this.tr.translate('error.dgis.address.notFound'), e.latLng);
              }
            });
          } else {
            this.noStoreDeliveryMsg(e.latLng);
          }
        });
        polygons.push(polygon);
      });
    }
    this.map.addListener('click', (e: any) => {
      this.noStoreDeliveryMsg(e.latLng);
    });

    renderMarkerFn();
    return Promise.resolve();
  }

  searchStreets(term: string, url: string, param: { regionId?: number; bbox?: string, bounds?: string }, attr: GisAttributeInput): Promise<GisItem[]> {
    return this.googleService.find(url, term, param.bounds).then(items => {
      items.forEach(item => {
        if (!item.point?.lat || !item.point.lon) {
          item.disabled = true;
        } else if (attr.store) {
          item.disabled = !hasStoreDelivery(item.point.lat, item.point.lon, attr.storeDeliveryMap, attr.store);
        } else if ((attr.stores ?? []).length > 0) {
          const deliveryStore = getDeliveryStore(item.point.lat, item.point.lon, attr.storeDeliveryMap, attr.stores);
          item.disabled = !deliveryStore;
        }
      });
      console.log('[google] load:', items);
      return items;
    }).catch(err => {
      console.log('[google] searchStreets error:', err);
      return [];
    });
  }

  showMap(url: string, attr: GisAttributeInput, clickHandler: MapClickHandler): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.googleService.load().then(() => {
        // @ts-ignore
        this.DG = window['DG'];
        setTimeout(() => {
          this.initGisMap(url, attr, clickHandler).then(resolve, reject);
        }, 100);
      }, reject);
    });
  }

  updateMarker(data: MapMarker, attr: GisAttributeInput): void {
    console.debug('[google] updateMarker', data);
    if (!this.map) {
      return;
    }
    this.closeAllInfoWindows();
    const {latitude, longitude, addressName} = data;
    if ((attr.store && hasStoreDelivery(latitude, longitude, attr.storeDeliveryMap, attr.store))
      || (attr.stores ?? []).length > 0 && !!getDeliveryStore(latitude, longitude, attr.storeDeliveryMap, attr.stores)) {
      this.showInfoWindow(addressName, new google.maps.LatLng(latitude, longitude));
    } else {
      this.noStoreDeliveryMsg(new google.maps.LatLng(latitude, longitude));
    }
  }

  showEmptyMap(url: string): Promise<void> {
    return Promise.resolve();
  }

  initGisEmptyMap(url: string): Promise<void> {
    return Promise.resolve();
  }

  private showInfoWindow(content: string, position: google.maps.LatLng | google.maps.LatLngLiteral) {
    const infoWindow: google.maps.InfoWindow = new google.maps.InfoWindow();
    infoWindow.setContent(content);
    infoWindow.setPosition(position);
    infoWindow.open(this.map);
    this.infoWindows.push(infoWindow);
  }

  private closeAllInfoWindows() {
    this.infoWindows.forEach(iw => iw.close());
  }

  private noStoreDeliveryMsg(position: google.maps.LatLng | google.maps.LatLngLiteral) {
    this.showInfoWindow(this.tr.translate('error.no.store.delivery'), position);
  }
}
