import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { GisItem } from './gis.service';

// TODO install @types/yandex-maps
// import ymaps from 'yandex-maps';

@Injectable({
  providedIn: 'root'
})
export class HxYandexService {

  private scriptLoaded = false;
  private scriptLoadPromise?: Promise<any>;

  constructor(
    private http: HttpClient,
  ) {
  }

  load(): Promise<void> {
    if (this.scriptLoaded) {
      return Promise.resolve((window as any).ymaps);
    } else {
      return this.loadScript('/yandex-maps/2.1/?load=package.full&lang=ru_RU');
    }
  }

  loadHeatMap(): Promise<void> {
    return this.loadHeatMapScript('https://yastatic.net/s3/mapsapi-jslibs/heatmap/0.0.1/heatmap.min.js');
  }

  find(name: string, url: string, params: any): Promise<GisItem[]> {
    let httpParams = new HttpParams();
    Object.keys(params).forEach(key => {
      httpParams = httpParams.append(key, params[key]);
    });
    httpParams = httpParams.append('geocode', name);
    return firstValueFrom(this.http.get<YandexResponse>(url + `/suggest`, {params: httpParams}))
      .then(res => res.response.GeoObjectCollection.featureMember
        .filter(item => item.GeoObject.Point.pos && item.GeoObject.metaDataProperty.GeocoderMetaData.kind === 'house')
        .map(item => this.convertToGisItem(item))
      );
  }

  private loadScript(url: string): Promise<void> {
    if (!this.scriptLoadPromise) {
      const self = this;
      this.scriptLoadPromise = new Promise<void>((resolve, reject) => {
        const script = document.createElement('script');
        script.innerHTML = '';
        script.src = url;
        script.async = false;
        script.defer = true;
        script.onload = () => {
          const ymaps = (window as any).ymaps;
          ymaps.ready(() => {
            self.scriptLoaded = true;
            resolve();
          }, reject);
        };
        document.body.appendChild(script);
      });
    }

    return this.scriptLoadPromise;
  }

  private loadHeatMapScript(url: string): Promise<void> {
      return  new Promise<void>((resolve) => {
        const script = document.createElement('script');
        script.innerHTML = '';
        script.src = url;
        script.async = false;
        script.defer = true;
        script.onload = () => {
          resolve();
        };
        document.body.appendChild(script);
      });
  }

  private convertToGisItem(feature: FeatureMemberEntity): GisItem {
    console.debug('[yandex] convert ', feature);
    const [lon, lat] = feature.GeoObject.Point.pos.split(' ').map(p => Number(p));
    const gisItem: GisItem = {
      addressName: feature.GeoObject.name,
      fullname: feature.GeoObject.metaDataProperty?.GeocoderMetaData?.text,
      point: {lat: lat, lon: lon},
    };
    const admDiv = feature.GeoObject.metaDataProperty?.GeocoderMetaData?.Address?.Components.map(c => ({
      name: c.name,
      type: c.kind,
    })) ?? [];

    if (admDiv.length > 0) {
      const divNameArr = admDiv
        .filter(div => div.type === 'district')
        .map(div => div.name);
      if (divNameArr.length > 0) {
        gisItem.admDivName = `${divNameArr.join(', ')}`;
      }
    }

    const houseNumberArr = feature.GeoObject.metaDataProperty?.GeocoderMetaData?.Address?.Components.filter(r => r.kind === 'house').map(c => c.name) ?? [];
    if (houseNumberArr.length > 0) {
      gisItem.buildingNumber = houseNumberArr[0];
    }
    const streetNameArr = feature.GeoObject.metaDataProperty?.GeocoderMetaData?.Address?.Components.filter(r => r.kind === 'street').map(c => c.name) ?? [];
    if (streetNameArr.length > 0) {
      gisItem.street = streetNameArr[0];
    }
    return gisItem;
  }
}

interface YandexResponse {
  response: Response;
}

interface Response {
  GeoObjectCollection: GeoObjectCollection;
}

interface GeoObjectCollection {
  metaDataProperty: MetaDataProperty;
  featureMember: FeatureMemberEntity[];
}

interface MetaDataProperty {
  GeocoderResponseMetaData: GeocoderResponseMetaData;
}

interface GeocoderResponseMetaData {
  boundedBy: BoundedBy;
  request: string;
  results: string;
  found: string;
}

interface BoundedBy {
  Envelope: Envelope;
}

interface Envelope {
  lowerCorner: string;
  upperCorner: string;
}

interface FeatureMemberEntity {
  GeoObject: GeoObject;
}

interface GeoObject {
  metaDataProperty: MetaDataProperty1;
  name: string;
  description: string;
  boundedBy: BoundedBy;
  Point: Point;
}

interface MetaDataProperty1 {
  GeocoderMetaData: GeocoderMetaData;
}

interface GeocoderMetaData {
  precision: string;
  text: string;
  kind: string;
  Address: Address;
  adm_div_name: string;
}

interface Address {
  country_code: string;
  formatted: string;
  Components: ComponentsEntity[];
}

interface ComponentsEntity {
  // https://yandex.com/dev/maps/geocoder/doc/desc/reference/akind.html
  kind: 'house' | 'street' | 'metro' | 'district' | 'locality' | 'area' | 'province' | 'country' | 'hydro' | 'vegetation' | 'airport' | 'other';
  name: string;
}

interface Point {
  pos: string;
}
