/**
 * Vanilla API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SeekDirection = 'prev' | 'next';

export const SeekDirection = {
    prev: 'prev' as SeekDirection,
    next: 'next' as SeekDirection
};

