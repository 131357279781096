import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';
import { DateRange, PagedList, StorageInvoiceModel, StorageInvoiceProductModel } from '../interface';
import { toHttpParams } from '../utils/globals';
import { StorageInvoiceStatus } from '../enum';
import { PaymentType } from '../vn-api';

interface StorageInvoiceListParam {
  page?: number;
  limit?: number;
  query?: string;
  createDate?: DateRange;
  incomingDate?: DateRange;
  paidDate?: DateRange;
  productInfoIds?: number[];
  supplierId?: number;
  spendCategoryId?: number;
  paid?: boolean;
  statuses?: StorageInvoiceStatus[];
  storeIds?: number[];
  dueDate?: DateRange;
  paymentApprovalId?: number;
}

export interface StorageInvoicePaymentRequest {
  invoiceIds: number[];
  paymentTypes: {organizationId: number, paymentType: PaymentType}[];
}

@Injectable({
  providedIn: 'root'
})
export class HxStorageInvoiceService {
  constructor(
    private http: HttpClient,
  ) {
  }

  getStorageInvoiceList(params?: StorageInvoiceListParam): Observable<PagedList<StorageInvoiceModel>> {
    return this.http.get<PagedList<StorageInvoiceModel>>(`/api/vanilla/storage-invoices`, {params: toHttpParams(params, true)});
  }

  getStorageInvoiceById(invoiceId: number, params?: {paymentApprovalId?: number}): Observable<StorageInvoiceModel> {
    return this.http.get<StorageInvoiceModel>(`/api/vanilla/storage-invoices/${invoiceId}`, {params: toHttpParams(params, true)});
  }

  createInvoice(): Observable<{ id: number }> {
    return this.http.post<{ id: number }>(`/api/vanilla/storage-invoices/initial`, {});
  }

  saveInvoice(request: StorageInvoiceRequest): Promise<{id: number}> {
    return firstValueFrom(this.http.post<{id: number}>(`/api/vanilla/storage-invoices`, request));
  }

  receiveInvoice(invoiceId: number, request: StorageInvoiceRequest): Observable<void> {
    return this.http.post<void>(`/api/vanilla/storage-invoices/${invoiceId}/received`, request);
  }

  rejectInvoice(invoiceId: number, reason: string): Observable<void> {
    return this.http.post<void>(`/api/vanilla/storage-invoices/${invoiceId}/rejected`, {reason: reason});
  }

  cancelInvoice(invoiceId: number, reason: string): Observable<void> {
    return this.http.post<void>(`/api/vanilla/storage-invoices/${invoiceId}/cancelled`, {reason: reason});
  }

  paidInvoice(request: StorageInvoicePaymentRequest): Observable<void> {
    return this.http.post<void>(`/api/vanilla/storage-invoices/paid`, request);
  }
}

export interface StorageInvoiceRequest {
  id?: number;
  incomingInvoiceNumber: string;
  incomingInvoiceDate: string;
  storeId: number;
  paymentType: PaymentType;
  supplierId: number;
  products: StorageInvoiceProductModel[];
  fileIds: number[];
  spendType: string;
  spendCategoryId: number;
}
